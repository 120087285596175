import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import communityService from '../services/communityService';
import { toast } from 'react-toastify';
import ReactPaginate from 'react-paginate'
import { getIdAndtypeCommunity } from '../features/postInCommunity/postInCommunitySlice';

function Community(props) {
    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate()
    // État pour gérer l'état du toggle
    const [isChecked, setIsChecked] = useState(false);
    const [loading, setLoading] = useState(false)
    const [communities, setCommunities] = useState([]);
    const [totalNumberOfPage, setTotalNumberOfPage] = useState(null);
    const [currentPage, setCurrentPage] = useState(0); 
    const [numberPerPage, setNumberPerPage] = useState(5);
    const [search, setSearch] = useState(""); 
    const [communityUpdateOrDelete, setCommunityUpdateOrDelete] = useState(null)
    const closeModalToDeleteACommunity = useRef(null);
    const closeModalToUpdate = useRef(null)

    // Fonction pour gérer le changement de l'état du toggle
    const handleToggle = () => {
        setIsChecked(!isChecked);
    };


    // Validation schema avec Yup
    const validationCreateCommunitySchema = Yup.object().shape({
        name: Yup.string()
            .required("Le nom est requis")
            .max(255, "Le nom doit comporter au maximum 255 caractères"),
        description: Yup.string()
            .required("La description est requise"),
        type: Yup.string()
            .oneOf(['local', 'diaspora', 'royaume'], "Type invalide")
            .required("Le type est requis"),
    });

    const handleClick = (nameCommunity) => {
        //dispatch(getIdAndtypeCommunity({ communityId: nameCommunity.id, type: nameCommunity.type }));
        // Naviguer vers /posts/:nameCommunity1
        navigate(`/posts-in-community/${nameCommunity.name}/${nameCommunity.id}`);
    };
    const listCommunities= async(search, page, numberPerPage) => {
        const data = {};
        data.search = search;
        data.page = page+1; 
        data.numberPerPage = numberPerPage;
        data.type = "local"
        setLoading(true);
        try {
            const responseListCommunities = await communityService.listAllCommunity(data)
            if (responseListCommunities.errorResponse) {
                toast.error(responseListCommunities.message)
            } else {
                setCommunities(responseListCommunities.results);
                setTotalNumberOfPage(responseListCommunities .numberOfPages)
            }
        } catch (error) {
            toast.error("Une erreur est obtenu pendant l'obtension des diaspora")
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        listCommunities(search, currentPage, numberPerPage);
    }, [search, currentPage, numberPerPage])

    const handleCreateCommunitySubmit = async (values) => {
        const communityData = {
            name: values.name,
            description: values.description,
            type: values.type,
        };
        const responseCreateCommunity = await communityService.createCommunity(communityData);
        try {
            if(responseCreateCommunity.errorResponse) {
                toast.error(responseCreateCommunity.message)
            } else {
                setIsChecked(false)
                listCommunities(search, currentPage, numberPerPage)
                toast("La création de la communauté a été éffectué avec succès")
            }
        } catch (error) {
            toast.error("Une érreur inattendue s'est produite lors de la creation du post")
            console.log("Une érreur a été survenu lors de la création d'un => ", error)
        } finally {
            setLoading(false)
        }
    };

    const deleteACommunity = async (communityUpdateOrDelete) => {
        // console.log('Voir id de la communauté ==> ', communityUpdateOrDelete.id)
        try {
            setLoading(true)
            const responseDeleteCommunity = await communityService.deleteACommunity(communityUpdateOrDelete.id)
            if (responseDeleteCommunity?.errorResponse) {
                toast.error(responseDeleteCommunity.message)
            } else {
                closeModalToDeleteACommunity.current.click()
                toast.success("La communauté a été Supprimé avec succès")
                listCommunities(search, currentPage, numberPerPage);
            }
            
        } catch (error) {
            console.error("Erreur lors de la suppression de la chambre:", error)
            toast.error("Une erreur inattendue s'est produite lors de la suppression de la communauté.")
        } finally {
            setLoading(false)
        }
    };

    const handleSearchAnObject = (event) => {
        const searchAnObject = event.target.value;
        setSearch(searchAnObject)
        setCurrentPage(0)
    };

    const handleChangeCurrentPage = (data) => {
        let currentPageu = data.selected;
        // console.log('mon current ==>>> ', currentPageu)
        setCurrentPage(currentPageu)
        return ;
    
    };

    const validationUpdateCommunitySchema = Yup.object().shape({
        name: Yup.string()
            .required('Le nom est requis')
            .max(255, 'Le nom ne doit pas dépasser 255 caractères'),
        type: Yup.string()
            .required('Le type est requis')
            .oneOf(['local', 'diaspora', 'royaume'], 'Le type doit être soit local, diaspora, soit royaume'),
        description: Yup.string()
            .required('La description est requise')
            .max(500, 'La description ne doit pas dépasser 500 caractères'),
    });

    const handleUpdateCommunitySubmit = async(values) => {
        const communityData = {
            name: values.name,
            description: values.description,
            type: values.type,
        };

        const responseUpdateCommunity = await communityService.updateDetailOfACommunity(communityUpdateOrDelete.id, communityData)
        try {
            if(responseUpdateCommunity.errorResponse) {
                toast.error(responseUpdateCommunity.message)
            } else {
                listCommunities(search, currentPage, numberPerPage)
                toast("La création de type danse traditionnelle a été éffectué avec succès")
                closeModalToUpdate.current.click()
            }
        } catch (error) {
            toast.error("Une érreur inattendue s'est produite lors de la creation du post")
            console.log("Une érreur a été survenu lors de la création d'un => ", error)
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            <Header />
            <div class="form-element-area">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="form-element-list">
                                <div class="basic-tb-hd">
                                    <h2>Activer la création d'une communauté</h2>
                                    <div class="TriSea-technologies-Switch">
                                        <input
                                            id="TriSeaPrimary"
                                            name="TriSea1"
                                            type="checkbox"
                                            checked={isChecked}
                                            onChange={handleToggle}
                                        />
                                        <label for="TriSeaPrimary" class="label-primary"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
            {isChecked ? 
                (
                    <div className="accordion-area">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="accordion-wn-wp mg-t-30">
                                        <div className="accordion-hd">
                                            <h2>Création d'une communauté</h2>
                                            {/* <p>Remplir le formulaire ci-dessous</p> */}
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-6 col-xs-12">
                                                <div className="accordion-stn">
                                                    <div className="panel-group" data-collapse-color="nk-indigo" id="accordionIndigo" role="tablist" aria-multiselectable="true">
                                                        <div className="panel panel-collapse notika-accrodion-cus">
                                                            <div className="panel-heading" role="tab">
                                                                <h4 className="panel-title">
                                                                    <a className="collapsed" data-toggle="collapse" data-parent="#accordionIndigo" href="#accordionIndigo-two" aria-expanded="false">
                                                                        Cliquez ici pour voir le formulaire
                                                                    </a>
                                                                </h4>
                                                            </div>
                                                            <div id="accordionIndigo-two" className="collapse animated zoomInLeft" role="tabpanel">
                                                                <div className="panel-body">
                                                                    <Formik
                                                                        initialValues={{
                                                                            name: '',
                                                                            description: '',
                                                                            type: 'local', // Par défaut, vous pouvez ajuster si nécessaire
                                                                        }}
                                                                        validationSchema={validationCreateCommunitySchema}
                                                                        onSubmit={handleCreateCommunitySubmit}
                                                                    >
                                                                        {() => (
                                                                            <Form className="form-example-wrap">
                                                                                {/* Champ Nom */}
                                                                                <div className="form-example-int">
                                                                                    <div className="form-group">
                                                                                        <label>Nom</label>
                                                                                        <div className="nk-int-st">
                                                                                            <Field
                                                                                                type="text"
                                                                                                name="name"
                                                                                                className="form-control input-sm"
                                                                                                placeholder="Enter votre nom"
                                                                                            />
                                                                                            <ErrorMessage name="name" component="div" style={{ color: 'red' }} />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                {/* Champ Description */}
                                                                                <div className="form-example-int">
                                                                                    <div className="form-group">
                                                                                        <label>Description</label>
                                                                                        <div className="nk-int-st">
                                                                                            <Field
                                                                                                as="textarea"
                                                                                                name="description"
                                                                                                className="form-control input-sm"
                                                                                                placeholder="Veuillez saisir la description"
                                                                                            />
                                                                                            <ErrorMessage name="description" component="div" style={{ color: 'red' }} />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                {/* Champ Type */}
                                                                                <div className="form-example-int">
                                                                                    <div className="form-group">
                                                                                        <label>Type</label>
                                                                                        <div className="nk-int-st">
                                                                                            <Field as="select" name="type" className="form-control input-sm">
                                                                                                <option value="local">Local</option>
                                                                                                <option value="diaspora">Diaspora</option>
                                                                                                <option value="royaume">Royaume</option>
                                                                                            </Field>
                                                                                            <ErrorMessage name="type" component="div" style={{ color: 'red' }} />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                {/* Bouton de soumission */}
                                                                                <div className="form-example-int mg-t-15">
                                                                                    <button 
                                                                                        type="submit" 
                                                                                        className="btn btn-success notika-btn-success"
                                                                                    >
                                                                                        Créer une communauté
                                                                                    </button>
                                                                                </div>
                                                                            </Form>
                                                                        )}
                                                                    </Formik>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                    {/* <!-- Data Table area Start--> */}
                        <div className="inbox-area" style={{marginTop: "15px"}}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div className="inbox-text-list sm-res-mg-t-30">
                                            <div className="form-group">
                                                <div className="nk-int-st search-input search-overt">
                                                    <input 
                                                        type="text" 
                                                        className="form-control" 
                                                        placeholder="Entrer le nom de la communauté..."
                                                        onChange={handleSearchAnObject}
                                                    />
                                                </div>
                                            </div>
                                            <div className="table-responsive">
                                                <table className="table table-hover table-inbox">
                                                    <tbody>
                                                        {communities?.map((community, index) => (
                                                            <tr key={index} className="unread">
                                                                <td><a role='button' onClick={() => handleClick(community)}>{community.name}</a></td>
                                                                <td><a role='button' onClick={() => handleClick(community)}>{community.type}</a></td>
                                                                <td>
                                                                    <button 
                                                                        type="button" 
                                                                        className="btn btn-info waves-effect" 
                                                                        data-toggle="modal" 
                                                                        data-target="#myModalDetailCommunity"
                                                                        onClick={() => setCommunityUpdateOrDelete(community)}
                                                                    >
                                                                        Details
                                                                    </button>
                                                                </td>
                                                                <td>
                                                                    <a 
                                                                        className="btn btn-primary notika-btn-primary waves-effect"
                                                                        data-toggle="modal"
                                                                        data-target="#myModalToUpdateCommunity"
                                                                        onClick={() => setCommunityUpdateOrDelete(community)}
                                                                    >
                                                                        Modifier
                                                                    </a>
                                                                </td>
                                                                <td>
                                                                    <a
                                                                        className="btn btn-danger notika-btn-primary waves-effect"
                                                                        data-toggle="modal"
                                                                        data-target="#myModalToDeleteACommunity"
                                                                        onClick={() => setCommunityUpdateOrDelete(community)}
                                                                    >
                                                                        Supprimer
                                                                    </a>
                                                                </td>
                                                                
                                                            </tr>
                                                        ))}   
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <ReactPaginate
                                            key={currentPage}
                                            initialPage={currentPage}
                                            previousLabel={"<"}
                                            nextLabel={">"}
                                            breakLabel={"..."}
                                            pageCount={totalNumberOfPage}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={3}
                                            onPageChange={handleChangeCurrentPage}
                                            containerClassName={"pagination justify-content-center"}
                                            pageClassName={"page-item"}
                                            pageLinkClassName={"page-link"}
                                            previousClassName={"page-item"}
                                            previousLinkClassName={"page-link"}
                                            nextClassName={"page-item"}
                                            nextLinkClassName={"page-link"}
                                            breakClassName={"page-item"}
                                            breakLinkClassName={"page-link"}
                                            activeLinkClassName={"active"}
                                            activeClassName={"active"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
            <div class="modal animated rubberBand" id="myModalToDeleteACommunity" role="dialog">
                <div class="modal-dialog modals-default">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button"  class="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div class="modal-body">
                            <h2>Suppression de la communauté {communityUpdateOrDelete?.name}</h2>
                            <p>Voulez-vous vraiment supprimer cette communauté ?</p>
                        </div>
                        <div class="modal-footer">
                            <button 
                                type="button" 
                                class="btn btn-default" 
                                data-dismiss="modal" 
                                onClick={() => deleteACommunity(communityUpdateOrDelete)}
                            >
                                Supprimer
                            </button>
                            <button 
                                type="button" 
                                class="btn btn-default" 
                                data-dismiss="modal"
                                ref={closeModalToDeleteACommunity}
                            >
                                Annuler
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* Modal pour le detail d'une communauté */}
            <div class="modal fade" id="myModalDetailCommunity" tabindex="-1" role="dialog" aria-labelledby="myModalDetailCommunityLabel">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <h4 class="modal-title" id="myModalDetailCommunityLabel">Informations</h4>
                        </div>
                        <div class="modal-body">
                            <p><strong>Nom :</strong> <span id="modal-name">{communityUpdateOrDelete?.name}</span></p>
                            <p><strong>Type :</strong> <span id="modal-type">{communityUpdateOrDelete?.type}</span></p>
                            <p><strong>Description :</strong> <span id="modal-description">{communityUpdateOrDelete?.description}</span></p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-default" data-dismiss="modal">Fermer</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* Madal pour modifier les champs d'une communauté */}
            <div className="modal fade" id="myModalToUpdateCommunity" role="dialog">
                <div className="modal-dialog modal-large">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal">
                                &times;
                            </button>
                        </div>
                        <div className="modal-body">
                            <h2>Modifier une communauté</h2>
                            {/* Formik form */}
                            <Formik
                                enableReinitialize={true}
                                initialValues={{
                                    name: communityUpdateOrDelete?.name,
                                    description: communityUpdateOrDelete?.description,
                                    type: communityUpdateOrDelete?.type ,
                                }}
                                validationSchema={validationUpdateCommunitySchema}
                                onSubmit={handleUpdateCommunitySubmit}
                            >
                                {() => (
                                    <Form className="form-example-wrap">
                                        <div className="form-example-int">
                                            <div className="form-group">
                                                <label>Nom</label>
                                                <div className="nk-int-st">
                                                    <Field
                                                        type="text"
                                                        name="name"
                                                        className="form-control input-sm"
                                                        placeholder="Entrez le nom"
                                                    />
                                                    <ErrorMessage
                                                        name="name"
                                                        component="div"
                                                        className="text-danger"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-example-int">
                                            <div className="form-group">
                                                <label>Type</label>
                                                <div className="nk-int-st">
                                                    <Field
                                                        as="select"
                                                        name="type"
                                                        className="form-control input-sm"
                                                    >
                                                        <option value="local">Local</option>
                                                        <option value="diaspora">Diaspora</option>
                                                        <option value="royaume">Royaume</option>
                                                    </Field>
                                                    <ErrorMessage
                                                        name="type"
                                                        component="div"
                                                        className="text-danger"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-example-int">
                                            <div className="form-group">
                                                <label>Description</label>
                                                <div className="nk-int-st">
                                                    <Field
                                                        as="textarea"
                                                        name="description"
                                                        className="form-control input-sm"
                                                        placeholder="Veuillez saisir la description"
                                                    />
                                                    <ErrorMessage
                                                        name="description"
                                                        component="div"
                                                        className="text-danger"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-example-int mg-t-15">
                                            <button
                                                type="submit"
                                                className="btn btn-success notika-btn-success"
                                            >
                                                Modifier
                                            </button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                        <div className="modal-footer">
                            <button
                                ref={closeModalToUpdate}
                                type="button"
                                className="btn btn-default"
                                data-dismiss="modal"
                            >
                                Fermer
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Community;