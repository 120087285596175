const authenticationBaseUrl = process.env.REACT_APP_BACKEND;


const backendUrls = {
    Authentication: {
        register: authenticationBaseUrl + "/api/user/create",
        login: authenticationBaseUrl + "/login",
    },
    USER: {
        createAnUser: authenticationBaseUrl + "/userCreate",
        getAllUsers: authenticationBaseUrl + "/users",
        deleteAnUser: authenticationBaseUrl + "/user/",
        updateAnUser: authenticationBaseUrl + "/user/"
        //updatePassword: authenticationBaseUrl + "/user/update-password"

    },
    Post: {
        createAPost: authenticationBaseUrl + "/postCreate",
        listPosts: authenticationBaseUrl + "/posts",
        deletePost: authenticationBaseUrl + "/post/",
        upadtePost: authenticationBaseUrl + "/post/",
        showPost: authenticationBaseUrl + "/post/",
        deleteAnImageOfAPost: authenticationBaseUrl + "/photo/",
        deleteAnVideoOfAPost: authenticationBaseUrl + "/video/"
    },
    Community: {
        getAllCommunities: authenticationBaseUrl + "/communities",
        createCommunity: authenticationBaseUrl + "/communityCreate",
        deleteACommunity: authenticationBaseUrl + "/community/",
        updateOfACommunity: authenticationBaseUrl + "/community/"
    }, 
    CulturalWealth: {
        getCulturalwealths: authenticationBaseUrl + "/posts",
        deleteCulturalWealth: authenticationBaseUrl + "/post/"
    },
    Photo: {
        addPhoto: authenticationBaseUrl + "/photo",
        deleteKingPhoto: authenticationBaseUrl + "/photo/"
    },
    Video: {
        addVideo: authenticationBaseUrl + "/video",
        deleteVideo: authenticationBaseUrl + "/video/"
    },
    RoyalPalace: {
        listPostsInRoyalPalace: authenticationBaseUrl + '/posts'
    },
    TreasureHouse: {
        listPostsForMuseum : authenticationBaseUrl + '/posts',
        deleteCulturalWealth: authenticationBaseUrl + "/post/",
        getDetailOfATreasureHouse: authenticationBaseUrl + "/post/",
        updateOfATreasureHouse: authenticationBaseUrl + "/post/"
    }, 
    Clan: {
        listClans: authenticationBaseUrl + "/clans",
        createClan: authenticationBaseUrl + "/clanCreate",
        deleteClan: authenticationBaseUrl + "/clan/",
        updateClan: authenticationBaseUrl + "/clan/"
    },
    Notable: {
        createNotable: authenticationBaseUrl + "/notableCreate",
        listNotables: authenticationBaseUrl + "/notables",
        updateNotable: authenticationBaseUrl + "/notable/",
        deleteNotable: authenticationBaseUrl + "/notable/"
    },
    ProgramBroadcast: {
        createProgramEmission: authenticationBaseUrl + "/emissionsCreate",
        listProgramEmission: authenticationBaseUrl + "/emissionsRadio",
        deleteProgramBroadcast : authenticationBaseUrl + "/emissions/",
        updateProgramBroadcast : authenticationBaseUrl + "/emissions/"
    }, 
    Podcast : {
        createPodcast: authenticationBaseUrl + "/podcastCreate",
        listPodcasts: authenticationBaseUrl + "/podcasts",
        deletePodcast: authenticationBaseUrl + "/podcast/",
        updatePodcast: authenticationBaseUrl + "/podcast/"
    }, 
    Team: {
        createTeam: authenticationBaseUrl + "/teamRadioCreate",
        listTeam: authenticationBaseUrl + "/teamRadio",
        deleteTeam: authenticationBaseUrl + "/teamRadio/"
    }, 
    Dynasty: {
        CreateAMemberOfTheDynasty: authenticationBaseUrl + "/dynastieCreate",
        listAllMembersOfTheDynasty: authenticationBaseUrl + "/dynasties",
        updateAMemberOfDynasty: authenticationBaseUrl + "/dynastie/",
        deleteAMemberOfDynasty: authenticationBaseUrl + "/dynastie/"
    },
    King: {
        getKing: authenticationBaseUrl + "/getKing",
    },

    Leader: {
        createLeader: authenticationBaseUrl + "/leaderCreate",
        listAllLeader: authenticationBaseUrl + "/leaders",
        getALeader: authenticationBaseUrl + "/leader/",
        updateLeader: authenticationBaseUrl + "/leader/"
    },
    Suggestions: {
        listSuggestions: authenticationBaseUrl + "/suggestion",
        deleteSuggestion: authenticationBaseUrl + "/suggestion/",
    }
    
}
export default backendUrls;