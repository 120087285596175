import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { getRole, hasRole } from '../auth/auth';


function Header(props) {
    const message = process.env.REACT_APP_WELCOME_MESSAGE || "Bienvenue à Bamougoum, terre de traditions et d'hospitalité";
    const [displayedText, setDisplayedText] = useState('');
    const [isDeleting, setIsDeleting] = useState(false);
    const [index, setIndex] = useState(0);

    useEffect(() => {
        let timeout;

        if (!isDeleting && index < message.length) {
        // Ajoute la prochaine lettre
        timeout = setTimeout(() => {
            setDisplayedText((prev) => prev + message[index]);
            setIndex((prev) => prev + 1);
        }, 100);
        } else if (isDeleting && index > 0) {
        // Supprime la dernière lettre
        timeout = setTimeout(() => {
            setDisplayedText((prev) => prev.slice(0, -1));
            setIndex((prev) => prev - 1);
        }, 50);
        } else if (index === message.length) {
        // Pause avant de supprimer
        timeout = setTimeout(() => setIsDeleting(true), 1000);
        } else if (isDeleting && index === 0) {
        // Redémarre le texte
        setIsDeleting(false);
        }

        return () => clearTimeout(timeout);
    }, [displayedText, isDeleting, index, message]);

    useEffect(() => {
        if (window.$)
        window.$('nav#dropdown').meanmenu()
    }, [])

    return (
        <>
            <div class="header-top-area">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <div class="logo-area text-white">
                                <a href="#" class="h3" style={{color: "white"}}>       
                                {/* <img src="/images/images/logo.PNG" alt="" />  */}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="header-personalize" style={{marginTop: "15px"}}>
                <div class="container">
                    <div class="row">
                        <div class="text-center" style={{display: "flex", justifyContent:"space-between"}}>
                            <div class="logo-area">
                                <a href="#">
                                    <img src="/images/images/logo.PNG" alt="" />
                                </a>
                            </div>
                            <div class="h2" style={{display: "flex", alignItems: "center"}}>
                                <p>{displayedText}</p>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            {/* <!-- End Header Top Area --> */}
            {/* <!-- Mobile Menu start --> */}
            <div class="mobile-menu-area">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="mobile-menu">
                                <nav id="dropdown">
                                    <ul class="mobile-menu-nav">
                                        <li>
                                            <Link to="/">Accueil</Link>
                                
                                        </li>
                                        {(getRole() == "ROLE_ADMIN" || "ROLE_MANAGER") && (
                                            <li>
                                                <Link to="/users">Utilisateurs</Link>
                                            </li>
                                        )}
                                        {/* <li><a data-toggle="collapse" data-target="#demo" href="#">Articles</a>
                                            <ul id="demo" class="collapse dropdown-header-top">
                                                <li><Link to="/">Lister les articles</Link></li>
                                                <li><Link to="/post">Créer un article</Link></li>
                                            </ul>
                                        </li> */}
                                        <li><a data-toggle="collapse" data-target="#Miscellaneousmob" href="#">Actualité</a>
                                            <ul id="Miscellaneousmob" class="collapse dropdown-header-top">
                                                <li>
                                                    <Link to="/actility-royal-place">Palais royal</Link>
                                                </li>
                                                <li>
                                                    <Link to="/community">Communautés</Link>
                                                </li>
                                                <li>
                                                    <Link to="/diaspora">Diapora</Link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li><a data-toggle="collapse" data-target="#Miscellaneousmob" href="#">Royaume</a>
                                            <ul id="Miscellaneousmob" class="collapse dropdown-header-top">
                                                <li>
                                                    <a href="/the-king">Le Roi</a>
                                                </li>
                                                <li>
                                                    <Link to="/events">Evènements</Link>
                                                </li>
                                                <li>
                                                    <Link to="/dynasty">Dynastie royale</Link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li><a data-toggle="collapse" data-target="#Pagemob" href="#">Cultures & traditions</a>
                                            <ul id="Pagemob" class="collapse dropdown-header-top">
                                                <li><Link to="/cultural-wealth">Richesses culturelles</Link>
                                                </li>
                                                <li><Link to="/clans">Notabilité</Link>
                                                </li>
                                                <li><Link to="/museum">Musée royal</Link>
                                                </li>
                                                <li><Link to="/traditional-danse">Danses traditionnelles</Link>
                                                </li>
                                                <li><Link to="/nguemba-language">Langue Nguemba</Link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li><a data-toggle="collapse" data-target="#Pagemob" href="#">Histoire</a>
                                            <ul id="Pagemob" class="collapse dropdown-header-top">
                                                <li><Link to="/genesis-kingdom">Genèse du royaume H</Link>
                                                </li>
                                                <li><Link to="/historical-events">Evènements historiques</Link>
                                                </li>
                                                <li><Link to="/leaders">Leaders</Link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li><a data-toggle="collapse" data-target="#Pagemob" href="#">Radio communautaire</a>
                                            <ul id="Pagemob" class="collapse dropdown-header-top">
                                                <li>
                                                    <Link to="/programs-broadcast">Programmes & Emissions</Link>
                                                </li>
                                                <li>
                                                    <Link to="/podcasts">Podcasts</Link>
                                                </li>
                                                <li>
                                                    <Link to="/teams-radio">Equipes</Link>
                                                </li>
                                                <li>
                                                    <Link to="/suggestions">Suggestions</Link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li><a data-toggle="collapse" data-target="#Pagemob" href="#">Communauté et diaspora</a>
                                            <ul id="Pagemob" class="collapse dropdown-header-top">
                                                <li><Link to="/community">Communautés</Link>
                                                </li>
                                                <li><Link to="/diaspora">Diaspora</Link>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Mobile Menu end --> */}
            {/* <!-- Main Menu area start--> */}
            <div class="main-menu-area mg-tb-40">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <ul class="nav nav-tabs notika-menu-wrap menu-it-icon-pro">
                                <li>
                                    <Link to="/">
                                        <i class="notika-icon notika-house"></i> 
                                        Accueil
                                    </Link>
                                </li>
                                {(getRole() == "ROLE_ADMIN" || "ROLE_MANAGER") && (
                                    <li>
                                        <Link to="/users">Utilisateurs</Link>
                                    </li>
                                )}    
                                <li>
                                    <a data-toggle="tab" href="#Actuality" aria-expanded="true">
                                        <i class="notika-icon notika-app"></i> 
                                        Actualité
                                    </a>
                                </li>
                                <li>
                                    <a data-toggle="tab" href="#Kingdom" aria-expanded="true">
                                        <i class="notika-icon notika-app"></i> 
                                        Royaume
                                    </a>
                                </li>
                                <li>
                                    <a data-toggle="tab" href="#Page">
                                        <i class="notika-icon notika-support"></i> 
                                        Cultures & traditions
                                    </a>
                                </li>
                                <li>
                                    <a data-toggle="tab" href="#History">
                                        <i class="notika-icon notika-support"></i> 
                                        Histoire
                                    </a>
                                </li>
                                <li><a data-toggle="tab" href="#CommunityRadio"><i class="notika-icon notika-support"></i> Radio communautaire</a>
                                </li>
                                {/* <li><a data-toggle="tab" href="#Propositions"><i class="notika-icon notika-support"></i> Propositions & statistiques</a>
                                </li> */}
                                <li><a data-toggle="tab" href="#Diaspora"><i class="notika-icon notika-support"></i> Communautés  & diaspora</a>
                                </li>
                            </ul>
                            <div class="tab-content custom-menu-content">
                                {
                                    getRole() === "ROLE_ADMIN" && (
                                        <div id="Tables" class="tab-pane notika-tab-menu-bg animated flipInX">
                                            <ul class="notika-main-menu-dropdown">
                                                <li><Link to="/users">Utilisateurs</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    )
                                }    
                                <div id="Forms" class="tab-pane notika-tab-menu-bg animated flipInX">
                                    <ul class="notika-main-menu-dropdown">
                                        <li><Link to="/">Lister les articles</Link>
                                        </li>
                                        <li><Link to="/post">Créer un article</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div id="Actuality" class="tab-pane notika-tab-menu-bg animated flipInX">
                                    <ul class="notika-main-menu-dropdown">
                                        <li><Link to="/actility-royal-place">Palais royal</Link>
                                        </li>
                                        <li><Link to="/community">Communautés</Link>
                                        </li>
                                        <li><Link to="/diaspora">Diapora</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div id="Kingdom" class="tab-pane notika-tab-menu-bg animated flipInX">
                                    <ul class="notika-main-menu-dropdown">
                                        <li><a href="/the-king">Le Roi</a>
                                        </li>
                                        <li><Link to="/events">Evènements</Link>
                                        </li>
                                        <li><Link to="/dynasty">Dynastie royale</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div id="Page" class="tab-pane notika-tab-menu-bg animated flipInX">
                                    <ul class="notika-main-menu-dropdown">
                                        <li>
                                            <Link to="/cultural-wealth">Richesse culturelle</Link>
                                        </li>
                                        <li>
                                            <Link to="/clans">Notabilité</Link>
                                        </li>
                                        <li>
                                            <Link to="/museum">Musée royal</Link>
                                        </li>
                                        <li>
                                            <Link to="/traditional-danse">Danses traditionnelles</Link>
                                        </li>
                                        <li>
                                            <Link to="/nguemba-language">Langue Nguemba</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div id="History" class="tab-pane notika-tab-menu-bg animated flipInX">
                                    <ul class="notika-main-menu-dropdown">
                                        <li>
                                            <Link to="/genesis-kingdom">Genèse du royaume</Link>
                                        </li>
                                        <li>
                                            <Link to="/historical-events">Evènements historiques</Link>
                                        </li>
                                        <li>
                                            <Link to="/leaders">Leaders</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div id="CommunityRadio" class="tab-pane notika-tab-menu-bg animated flipInX">
                                    <ul class="notika-main-menu-dropdown">
                                        <li>
                                            <Link to="/programs-broadcast">Programmes & Emissions</Link>
                                        </li>
                                        <li>
                                            <Link to="/podcasts">Podcasts</Link>
                                        </li>
                                        <li>
                                            <Link to="/teams-radio">Equipes</Link>
                                        </li>
                                        <li>
                                            <Link to="/suggestions">Suggestion</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div id="Diaspora" class="tab-pane notika-tab-menu-bg animated flipInX">
                                    <ul class="notika-main-menu-dropdown">
                                        <li>
                                            <Link to="/community">Communautés</Link>
                                        </li>
                                        <li>
                                            <Link to="/diaspora">Diaspora</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Main Menu area End--> */}
	        {/* <!-- Breadcomb area Start--> */}
        </>
    );
}

export default Header;