import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

const Suggestions = () => {
    return (
        <>
            <Header />
            <div class="container">
    <h2 class="text-center">Suggestion des utilisateurs</h2>
    <div class="suggestions-container">
      {/* <!-- Suggestion 1 --> */}
      <div class="suggestion-item">
        <div class="user-info">
          <div class="user-name">Alice</div>
          <div class="user-message">"This is Alice's message.This is Alice's message. This is Alice's message. This is Alice's message. This is Alice's message."</div>
        </div>
        <button class="btn btn-danger btn-delete">Delete</button>
      </div>
      {/* <!-- Suggestion 2 --> */}
      <div class="suggestion-item">
        <div class="user-info">
          <div class="user-name">Bob</div>
          <div class="user-message">"Bob's message goes here. This is Alice's message. This is Alice's message. This is Alice's message. This is Alice's message. This is Alice's message. This is Alice's message. This is Alice's message. This is Alice's message. This is Alice's message."</div>
        </div>
        <button class="btn btn-danger btn-delete">Delete</button>
      </div>
      {/* <!-- Suggestion 3 --> */} 
      <div class="suggestion-item">
        <div class="user-info">
          <div class="user-name">Charlie</div>
          <div class="user-message">"A quick thought from Charlie."</div>
        </div>
        <button class="btn btn-danger btn-delete">Delete</button>
      </div>
      {/* <!-- Suggestion 4 --> */}
      <div class="suggestion-item">
        <div class="user-info">
          <div class="user-name">David</div>
          <div class="user-message">"David's contribution to the conversation."</div>
        </div>
        <button class="btn btn-danger btn-delete">Delete</button>
      </div>
    </div>
  </div>
            <Footer />
        </>
    );
};

export default Suggestions;