import React, { useEffect, useRef, useState } from 'react';
import Header from '../components/Header';
import postService from '../services/postService';
import { toast } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import communityService from '../services/communityService';
import royalPaceService from '../services/royalPalaceService';
import { useSelector } from 'react-redux';
import notableService from '../services/notableService';
import clanService from '../services/clanService';
import Footer from '../components/Footer';
import ReactPaginate from 'react-paginate';

function NotableInClan(props) {
    // Utilisez useSelector pour accéder à l'état de postInCommunity
    const { id, name } = useSelector((state) => state.getNameOfAclan);
    // État pour gérer l'état du toggle
    const [isChecked, setIsChecked] = useState(false);
    const [loading, setLoading] = useState(false)
    const [notables, setNotables] = useState([])
    const [search, setSearch] = useState("")
    const [currentPage, setCurrentPage] = useState(0);
    const [totalResult, setTotalResult] = useState(null);
    const [totalNumberOfPage, setTotalNumberOfPage] = useState(0)
    const [totalNumberPerPage, setTotalNumberPerPage] = useState(8)
    const [idRoyalPlace, setIdRoyalPlace] = useState(null);
    const [posts, setPosts] = useState([]);
    const [numberPerPage, setNumberPerPage] = useState(10)
    const [notableUpdateOrDelete, setNotableUpdateOrDelete] = useState(null);
    const closeModalToUpdate = useRef(null);
    const [clans, setClans] = useState([]);
    const closeModalToDelete = useRef(null)

    // Fonction pour gérer le changement de l'état du toggle
    const handleToggle = () => {
        setIsChecked(!isChecked);
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('Le nom est obligatoire')
            .min(2, 'Le nom doit comporter au moins 2 caractères')
            .max(50, 'Le nom ne doit pas dépasser 50 caractères'),
        
        photo: Yup.mixed()
            .required('La photo est obligatoire')
            .test(
                'fileType',
                'Le fichier doit être une image de type jpg ou jpeg',
                (value) => !value || (value && ['image/jpeg', 'image/jpg'].includes(value.type))
            )
            .test(
                'fileSize',
                'La taille de l\'image ne doit pas dépasser 2MB',
                (value) => !value || (value && value.size <= 2 * 1024 * 1024)
            ),
        
        description: Yup.string()
            .required('La description est obligatoire')
            .min(10, 'La description doit comporter au moins 10 caractères'),
        
        description2: Yup.string()
            .required('La deuxième description est obligatoire')
            .min(10, 'La deuxième description doit comporter au moins 10 caractères'),
    
        job: Yup.string()
            .required('Le métier est obligatoire')
            .min(2, 'Le métier doit comporter au moins 2 caractères')
            .max(50, 'Le métier ne doit pas dépasser 50 caractères'),
    });

    const listAllNotableByClan = async (search, currentPage, numberPerPage) => {
        const data = {}
        data.search = search;
        data.page = currentPage;
        data.numberPerPage = numberPerPage;
        data.clan_id = id;

        try {
            const response = await notableService.listNotable(data)
            if (response.errorResponse) {
                toast.error(response.message)
            } else {
                setNotables(response.results);
                setTotalNumberOfPage(response.numberOfPages)
                setTotalResult(response.totalResult);
            }    
        } catch (error) {
            
        }
    }

    const handleSubmit = async (values) => {
        alert(JSON.stringify(values, null, 4))
        setLoading(true);
        const notableData = new FormData()
        notableData.append('name', values.name);
        notableData.append('photo', values.photo);
        notableData.append('description', values.description);
        notableData.append('description2', values.description2);
        notableData.append('titre', values.job);
        notableData.append('clan_id', id);

        //alert(values, null, 4)
        const responseCreateNotable = await notableService.createNotable(notableData)

        try {
            if(responseCreateNotable.errorResponse) {
                toast.error(responseCreateNotable.message)
            } else {
                setIsChecked(false)
                listAllNotableByClan(search, currentPage, numberPerPage)
                toast("La création du notable a été éffectué avec succès")
    
            }
        } catch (error) {
            toast.error("Une érreur inattendue s'est produite lors de la creation du notable")
            console.log("Une érreur a été survenu lors de la création d'un => ", error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        listAllNotableByClan(search, currentPage, numberPerPage);
    }, [search, currentPage, numberPerPage, id])

    const handleSearchRoyalPalace = (event) => {
        const keywords = event.target.value;
        setSearch(keywords);
        setCurrentPage(0);
    };

    const handleUpdateSubmit = async (values) => {

        const notableUpdateData = {
            name: values.name,
            titre: values.titre,
            description: values.description,
            description2: values.description2,
            clan_id: values.clan_id
        }

        const responseUpdateNotable = await notableService.updateNotable(notableUpdateOrDelete.id, notableUpdateData);
        try {
            if (responseUpdateNotable?.errorResponse) {
                toast.error(responseUpdateNotable.message)
            } else {
                listAllNotableByClan(search, currentPage, numberPerPage);
                closeModalToUpdate.current.click()
            }
        } catch (error) {
            toast.error("Une erreur inattendue s'est produite pendant la modification de ce clan")
        } finally {
            setLoading(false);
        }
    };

    const listAllClans = async(search, page, numberPerPage) => {
        const data = {};
        data.search = search;
        data.page = page; 
        data.numberPerPage = 20;
        // data.type = "diaspora"
        setLoading(true);
        try {
            const responseClans = await clanService.listClans(data)
            if (responseClans.errorResponse) {
                toast.error(responseClans.message)
            } else {
                setClans(responseClans.results);
                setTotalNumberOfPage(responseClans.numberOfPages)
            }
        } catch (error) {
            toast.error("Une erreur est obtenu pendant l'obtension des diaspora")
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        listAllClans(search, currentPage, numberPerPage)
    }, [])

    const handleDelete = async(id) => {
        try {
            setLoading(true)
            const response = await notableService.deleteANotable(id)
            if (response?.errorResponse) {
                toast.error(response.message)
            } else {
                toast.success("La team a été Supprimé avec succès")
                listAllNotableByClan(search, currentPage, numberPerPage);
                closeModalToDelete.current.click();
            }
            
        } catch (error) {
            console.error("Erreur lors de la suppression du post chambre:", error)
            toast.error("Une erreur inattendue s'est produite lors de la suppression de la chambre.")
        } finally {
            setLoading(false)
        }
    }

    const handleChangeCurrentPage = (data) => {
        let currentPageu = data.selected;
        setCurrentPage(currentPageu)
        return ;
    
    };

    //console.log('voir le type et idCommunity ===>> ', {id: communityId, type: type})
    return (
        <>
            <Header />
            <div class="form-element-area">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="form-element-list">
                                <div class="basic-tb-hd">
                                    <h2>Activer la création d'un Post lié aux activités royal</h2>
                                    <div class="TriSea-technologies-Switch">
                                        <input
                                            id="TriSeaPrimary"
                                            name="TriSea1"
                                            type="checkbox"
                                            checked={isChecked}
                                            onChange={handleToggle}
                                        />
                                        <label for="TriSeaPrimary" class="label-primary"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
            {isChecked ? (
                <div className="accordion-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="accordion-wn-wp mg-t-30">
                                    <div className="accordion-hd">
                                        <h2>Création d'un Notable</h2>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-6 col-xs-12">
                                            <div className="accordion-stn">
                                                <div className="panel-group" data-collapse-color="nk-indigo" id="accordionIndigo" role="tablist" aria-multiselectable="true">
                                                    <div className="panel panel-collapse notika-accrodion-cus">
                                                        <div className="panel-heading" role="tab">
                                                            <h4 className="panel-title">
                                                                <a className="collapsed" data-toggle="collapse" data-parent="#accordionIndigo" href="#accordionIndigo-two" aria-expanded="false">
                                                                    Cliquez ici pour voir le formulaire de création d'un notable
                                                                </a>
                                                            </h4>
                                                        </div>
                                                        <div id="accordionIndigo-two" className="collapse animated zoomInLeft" role="tabpanel">
                                                            <div className="panel-body">
                                                                <Formik
                                                                    initialValues={{
                                                                        name: '',
                                                                        photo: null, // Photo initialisée avec `null`
                                                                        description: '',
                                                                        description2: '',
                                                                        job: '',
                                                                    }}
                                                                    validationSchema={validationSchema}
                                                                    onSubmit={handleSubmit}
                                                                >
                                                                    {({ setFieldValue }) => (
                                                                        <Form>
                                                                            {/* Champ Name */}
                                                                            <div className="form-example-int">
                                                                                <div className="form-group">
                                                                                    <label htmlFor="name">Nom</label>
                                                                                    <div className="nk-int-st">
                                                                                        <Field
                                                                                            type="text"
                                                                                            name="name"
                                                                                            id="name"
                                                                                            className="form-control input-sm"
                                                                                            placeholder="Entrez le nom"
                                                                                        />
                                                                                        <ErrorMessage name="name" component="div" style={{ color: 'red' }} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            {/* Champ Photo */}
                                                                            <div className="form-example-int mg-t-15">
                                                                                <div className="form-group">
                                                                                    <label htmlFor="photo">Photo</label>
                                                                                    <div className="nk-int-st">
                                                                                        <input
                                                                                            type="file"
                                                                                            id="photo"
                                                                                            className="form-control input-sm"
                                                                                            onChange={(event) => setFieldValue('photo', event.target.files[0])}
                                                                                        />
                                                                                        <ErrorMessage name="photo" component="div" style={{ color: 'red' }} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            {/* Champ Description */}
                                                                            <div className="form-example-int mg-t-15">
                                                                                <div className="form-group">
                                                                                    <label htmlFor="description">Description</label>
                                                                                    <div className="nk-int-st">
                                                                                        <Field
                                                                                            as="textarea"
                                                                                            name="description"
                                                                                            className="form-control input-sm"
                                                                                            placeholder="Veuillez saisir la description"
                                                                                        />
                                                                                        <ErrorMessage name="description" component="div" style={{ color: 'red' }} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            {/* Champ Description2 */}
                                                                            <div className="form-example-int mg-t-15">
                                                                                <div className="form-group">
                                                                                    <label htmlFor="description2">Description 2</label>
                                                                                    <div className="nk-int-st">
                                                                                        <Field
                                                                                            as="textarea"
                                                                                            name="description2"
                                                                                            className="form-control input-sm"
                                                                                            placeholder="Veuillez saisir la deuxième description"
                                                                                        />
                                                                                        <ErrorMessage name="description2" component="div" style={{ color: 'red' }} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            {/* Champ Job */}
                                                                            <div className="form-example-int mg-t-15">
                                                                                <div className="form-group">
                                                                                    <label htmlFor="job">Métier</label>
                                                                                    <div className="nk-int-st">
                                                                                        <Field
                                                                                            type="text"
                                                                                            name="job"
                                                                                            id="job"
                                                                                            className="form-control input-sm"
                                                                                            placeholder="Entrez le métier"
                                                                                        />
                                                                                        <ErrorMessage name="job" component="div" style={{ color: 'red' }} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            {/* Bouton de soumission */}
                                                                            <div className="form-example-int mg-t-15">
                                                                                <button type="submit" className="btn btn-success notika-btn-success">
                                                                                    Créer un post
                                                                                </button>
                                                                            </div>
                                                                        </Form>
                                                                    )}
                                                                </Formik>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <div className="accordion-area">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="accordion-wn-wp mg-t-30">
                                        <div className="accordion-hd">
                                            <h2>Filtre par la recherche et par le nombre d'élément</h2>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-6 col-xs-12">
                                                <div className="accordion-stn" >
                                                    <div className="col-6">
                                                        <div class="dataTables_length" id="data-table-basic_length">
                                                            <label>
                                                                Présente
                                                                <select name="data-table-basic_length" aria-controls="data-table-basic" class="">
                                                                    <option value="10">10</option>
                                                                    <option value="25">25</option>
                                                                    <option value="50">50</option>
                                                                    <option value="100">100</option>
                                                                </select> 
                                                                entrées
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className='col-6'>
                                                        <div id="data-table-basic_filter" class="dataTables_filter">
                                                            <label>Rechercher:
                                                                <input 
                                                                    type="search" 
                                                                    className="" 
                                                                    placeholder="Titre, description" 
                                                                    aria-controls="data-table-basic"
                                                                    onChange={handleSearchRoyalPalace}
                                                                    />
                                                            </label>
                                                        </div>
                                                    </div>    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-area" style={{ marginTop: "15px" }}>       
                        <div className="container">    
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="container mg-t-30">
                                        <div style={{ display: "flex", gap: "20px", flexWrap: "wrap", justifyContent: "center" }}>
                                            {notables.map((notable, index) => (
                                                <div key={index} className="card" style={{
                                                    width: "350px", 
                                                    borderRadius: "8px", 
                                                    boxShadow: "0 4px 8px rgba(0,0,0,0.1)", 
                                                    overflow: "hidden", 
                                                    backgroundColor: "#fff",
                                                    transition: "transform 0.3s",
                                                    cursor: "pointer"
                                                }}
                                                onMouseEnter={(e) => e.currentTarget.style.transform = "scale(1.03)"}
                                                onMouseLeave={(e) => e.currentTarget.style.transform = "scale(1)"}>
                                                    <div style={{ height: "200px", overflow: "hidden", backgroundColor: "#f4f4f4" }}>
                                                        <div style={{ 
                                                            width: "100%", 
                                                            height: "100%", 
                                                            display: "flex", 
                                                            justifyContent: "center", 
                                                            alignItems: "center" 
                                                        }}>
                                                            {notable?.photo ? (
                                                                <img 
                                                                    style={{ width: "100%", height: "100%", objectFit: "cover" }} 
                                                                    src={`${process.env.REACT_APP_SERVER}${notable.photo}`} 
                                                                    alt={notable.title} 
                                                                />
                                                            ) : (
                                                                <video style={{ width: "100%", height: "100%" }} controls>
                                                                    <source src={notable?.mediaurl} type="video/mp4" />
                                                                    Votre navigateur ne supporte pas la lecture des vidéos.
                                                                </video>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div style={{ padding: "15px", display: "flex", flexDirection: "column", justifyContent: "space-between", height: "150px" }}>
                                                        {/* <div>
                                                            <h2 style={{ fontSize: "18px", fontWeight: "bold", color: "#333", marginBottom: "10px" }}>{notable.title}</h2>
                                                            <p style={{ fontSize: "14px", color: "#555", marginBottom: "15px" }}>{notable.description}</p>
                                                        </div> */}
                                                        <div style={{ display: "flex", justifyContent: "space-between", gap: "10px", flexWrap: "wrap", bottom: "23px", position: "absolute" }} className="button-group">
                                                            <button
                                                                className="btn btn-primary"
                                                                style={{ backgroundColor: "#4caf50", borderColor: "#4caf50", color: "#fff", padding: "8px 12px" }}
                                                                role="button"
                                                                data-toggle="modal"
                                                                data-target="#myModalToDescribeANotable"
                                                                onClick={() => setNotableUpdateOrDelete(notable)}
                                                            >
                                                                Description
                                                            </button>
                                                            <button
                                                                className="btn btn-warning"
                                                                style={{ padding: "8px 12px", color: "#fff" }}
                                                                data-toggle="modal"
                                                                data-target="#myModalToUpdateNotable"
                                                                onClick={() => setNotableUpdateOrDelete(notable)}
                                                            >
                                                                Modifier
                                                            </button>
                                                            <button 
                                                                className="btn btn-danger" 
                                                                style={{ padding: "8px 12px" }}
                                                                role="button"
                                                                data-toggle="modal"
                                                                data-target="#confirmDeleteNotableModal"
                                                                onClick={() => setNotableUpdateOrDelete(notable)}
                                                            >
                                                                Supprimer
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <ReactPaginate
                                        key={currentPage}
                                        initialPage={currentPage}
                                        previousLabel={"<"}
                                        nextLabel={">"}
                                        breakLabel={"..."}
                                        pageCount={totalNumberOfPage}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={3}
                                        onPageChange={handleChangeCurrentPage}
                                        containerClassName={"pagination justify-content-center"}
                                        pageClassName={"page-item"}
                                        pageLinkClassName={"page-link"}
                                        previousClassName={"page-item"}
                                        previousLinkClassName={"page-link"}
                                        nextClassName={"page-item"}
                                        nextLinkClassName={"page-link"}
                                        breakClassName={"page-item"}
                                        breakLinkClassName={"page-link"}
                                        activeLinkClassName={"active"}
                                        activeClassName={"active"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                </>

            )}
            <div class="modal fade" id="myModaltwo" role="dialog">
                <div class="modal-dialog modal-large">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div class="modal-body">
                            <h2>Modal title</h2>
                            <p>Curabitur blandit mollis lacus. Nulla sit amet est. Suspendisse nisl elit, rhoncus eget, elementum ac, condimentum eget, diam. Donec mi odio, faucibus at, scelerisque quis, convallis in, nisi. Cras sagittis.</p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-default" data-dismiss="modal">Save changes</button>
                            <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="myModalthree" role="dialog">
                <div class="modal-dialog modal-large">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div class="modal-body">
                            <h2>Modal title</h2>
                            <p>Curabitur blandit mollis lacus. Nulla sit amet est. Suspendisse nisl elit, rhoncus eget, elementum ac, condimentum eget, diam. Donec mi odio, faucibus at, scelerisque quis, convallis in, nisi. Cras sagittis.</p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-default" data-dismiss="modal">Save changes</button>
                            <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="myModalToDescribeANotable" role="dialog">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal">
                                &times;
                            </button>
                            <h4 className="modal-title">Description d'un Notable</h4>
                        </div>
                        <div className="modal-body">
                            {/* Nom */}
                            <div className="form-group">
                                <label><strong>Nom :</strong></label>
                                <p>{notableUpdateOrDelete?.name}</p>
                            </div>
                            {/* Titre */}
                            <div className="form-group">
                                <label><strong>Titre :</strong></label>
                                <p>{notableUpdateOrDelete?.titre}</p>
                            </div>
                            {/* Description */}
                            <div className="form-group">
                                <label><strong>Description :</strong></label>
                                <p>{notableUpdateOrDelete?.description}</p>
                            </div>
                            {/* Description2 */}
                            <div className="form-group">
                                <label><strong>Description Additionnelle :</strong></label>
                                <p>{notableUpdateOrDelete?.description2}</p>
                            </div>
                            {/* Photo */}
                            <div className="form-group">
                                <label><strong>Photo :</strong></label>
                                <div style={{ height: '500px', overflow: 'hidden' }}>
                                    <img 
                                        src={process.env.REACT_APP_SERVER+notableUpdateOrDelete?.photo} 
                                        alt="Notable" 
                                        className="img-responsive" 
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default" data-dismiss="modal">
                                Fermer
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* Modal to update notable */}
            <div className="modal fade" id="myModalToUpdateNotable" role="dialog">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal">
                                &times;
                            </button>
                            <h4 className="modal-title">Modifier un Notable</h4>
                        </div>
                        <div className="modal-body">
                            <Formik
                                enableReinitialize={true}
                                initialValues={{
                                    name: notableUpdateOrDelete?.name || '', // Initialiser avec les valeurs actuelles ou laisser vide
                                    // photo: notableUpdateOrDelete?.photo || null,
                                    titre: notableUpdateOrDelete?.titre || '',
                                    description: notableUpdateOrDelete?.description || '',
                                    description2: notableUpdateOrDelete?.description2 || '',
                                    clan_id: notableUpdateOrDelete?.clan_id || '',
                                }}
                                validationSchema={Yup.object({
                                    name: Yup.string().required('Le nom est requis'),
                                    // photo: Yup.mixed().required('La photo est requise'),
                                    titre: Yup.string().required('Le titre est requis'),
                                    description: Yup.string().required('La description est requise'),
                                    description2: Yup.string(),
                                    clan_id: Yup.string().required('Le clan est requis'),
                                })}
                                onSubmit={handleUpdateSubmit}
                            >
                                {({ setFieldValue }) => (
                                    <Form className="form-example-wrap">
                                        {/* Champ Name */}
                                        <div className="form-group">
                                            <label>Nom</label>
                                            <Field
                                                type="text"
                                                name="name"
                                                className="form-control input-sm"
                                                placeholder="Entrez le nom"
                                            />
                                            <ErrorMessage
                                                name="name"
                                                component="div"
                                                className="text-danger"
                                            />
                                        </div>

                                        {/* Champ Photo */}
                                        {/* <div className="form-group">
                                            <label>Photo</label>
                                            <input
                                                type="file"
                                                name="photo"
                                                className="form-control input-sm"
                                                accept=".jpg, .jpeg, .png"
                                                onChange={(event) => setFieldValue('photo', event.currentTarget.files[0])}
                                            />
                                            <ErrorMessage
                                                name="photo"
                                                component="div"
                                                className="text-danger"
                                            />
                                        </div> */}

                                        {/* Champ Titre */}
                                        <div className="form-group">
                                            <label>Titre</label>
                                            <Field
                                                type="text"
                                                name="titre"
                                                className="form-control input-sm"
                                                placeholder="Entrez le titre"
                                            />
                                            <ErrorMessage
                                                name="titre"
                                                component="div"
                                                className="text-danger"
                                            />
                                        </div>

                                        {/* Champ Description */}
                                        <div className="form-group">
                                            <label>Description</label>
                                            <Field
                                                as="textarea"
                                                name="description"
                                                className="form-control input-sm"
                                                placeholder="Veuillez saisir la description"
                                            />
                                            <ErrorMessage
                                                name="description"
                                                component="div"
                                                className="text-danger"
                                            />
                                        </div>

                                        {/* Champ Description2 */}
                                        <div className="form-group">
                                            <label>Description Additionnelle</label>
                                            <Field
                                                as="textarea"
                                                name="description2"
                                                className="form-control input-sm"
                                                placeholder="Entrez des informations supplémentaires"
                                            />
                                            <ErrorMessage
                                                name="description2"
                                                component="div"
                                                className="text-danger"
                                            />
                                        </div>

                                        {/* Champ Clan ID */}
                                        <div className="form-group">
                                            <label>Clan</label>
                                            <Field as="select" name="clan_id" className="form-control input-sm">
                                                <option value="">Sélectionnez un clan</option>
                                                {clans.map(clan => (
                                                    <option key={clan.id} value={clan.id}>
                                                        {clan.name}
                                                    </option>
                                                ))}
                                            </Field>
                                            <ErrorMessage
                                                name="clan_id"
                                                component="div"
                                                className="text-danger"
                                            />
                                        </div>

                                        {/* Bouton Soumettre */}
                                        <div className="form-group">
                                            <button
                                                type="submit"
                                                className="btn btn-success notika-btn-success"
                                            >
                                                Modifier
                                            </button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                        <div className="modal-footer">
                            <button 
                                type="button" 
                                className="btn btn-default" 
                                data-dismiss="modal"
                                ref={closeModalToUpdate}
                            >
                                Fermer
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Modal de confirmation de suppression --> */}
            <div className="modal fade" id="confirmDeleteNotableModal" role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                            <h4 className="modal-title">Confirmation de Suppression</h4>
                        </div>
                        <div className="modal-body">
                            <p>Êtes-vous sûr de vouloir supprimer cet élément ? Cette action est irréversible.</p>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-default"
                                data-dismiss="modal"
                            >
                                Annuler
                            </button>
                            <button
                                type="button"
                                className="btn btn-danger"
                                onClick={() => handleDelete(notableUpdateOrDelete.id)}
                            >
                                Confirmer la Suppression
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default NotableInClan;