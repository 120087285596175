import React, { useEffect, useRef, useState } from 'react';
import Header from '../components/Header';
import postService from '../services/postService';
import { toast } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import communityService from '../services/communityService';
import royalPaceService from '../services/royalPalaceService';
import { useSelector } from 'react-redux';
import notableService from '../services/notableService';
import clanService from '../services/clanService';
import dynastyService from '../services/dynastyService';
import Footer from '../components/Footer';

function Dynasty(props) {
    // Utilisez useSelector pour accéder à l'état de postInCommunity
    // État pour gérer l'état du toggle
    const [isChecked, setIsChecked] = useState(false);
    const [loading, setLoading] = useState(false)
    const [notables, setNotables] = useState([])
    const [search, setSearch] = useState("")
    const [currentPage, setCurrentPage] = useState(0);
    const [totalResult, setTotalResult] = useState(null);
    const [totalNumberOfPage, setTotalNumberOfPage] = useState(0)
    const [totalNumberPerPage, setTotalNumberPerPage] = useState(8)
    const [idRoyalPlace, setIdRoyalPlace] = useState(null);
    const [posts, setPosts] = useState([]);
    const [numberPerPage, setNumberPerPage] = useState(40)
    const [notableUpdateOrDelete, setNotableUpdateOrDelete] = useState(null);
    const closeModalToUpdate = useRef(null);
    const [clans, setClans] = useState([]);
    const closeModalToDelete = useRef(null)
    const [memberDynastyToUpdate, setMemberDynastyToUpdate] = useState(null)

    // Fonction pour gérer le changement de l'état du toggle
    const handleToggle = () => {
        setIsChecked(!isChecked);
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('Le nom est obligatoire')
            .min(2, 'Le nom doit comporter au moins 2 caractères')
            .max(50, 'Le nom ne doit pas dépasser 50 caractères'),
        
        photo: Yup.mixed()
            .required('La photo est obligatoire')
            .test(
                'fileType',
                'Le fichier doit être une image de type jpg ou jpeg',
                (value) => !value || (value && ['image/jpeg', 'image/jpg'].includes(value.type))
            )
            .test(
                'fileSize',
                'La taille de l\'image ne doit pas dépasser 2MB',
                (value) => !value || (value && value.size <= 2 * 1024 * 1024)
            ),
        
        description: Yup.string()
            .required('La description est obligatoire')
            .min(10, 'La description doit comporter au moins 10 caractères'),
        
        description2: Yup.string()
            .required('La deuxième description est obligatoire')
            .min(10, 'La deuxième description doit comporter au moins 10 caractères'),
    
        number: Yup.number()
            .required('Le numéro est obligatoire')
    });

    const listAllMembersOfTheDynasty = async (search, currentPage, numberPerPage) => {
        const data = {}
        data.search = search;
        data.page = currentPage;
        data.numberPerPage = numberPerPage;

        try {
            const response = await dynastyService.listAllMembersOfTheDynasty(data)
            if (response.errorResponse) {
                toast.error(response.message)
            } else {
                setNotables(response.results);
                setTotalNumberOfPage(response.totalNumberOfPage)
                setTotalResult(response.totalResult);
            }    
        } catch (error) {
            
        }
    }

    const handleSubmit = async (values) => {
        setLoading(true);
        const memberOfTheDynastyData = new FormData()
        memberOfTheDynastyData.append('name', values.name);
        memberOfTheDynastyData.append('photo', values.photo);
        memberOfTheDynastyData.append('description', values.description);
        memberOfTheDynastyData.append('description2', values.description2);
        memberOfTheDynastyData.append('number', values.number);

        //alert(values, null, 4)
        const responseAMemberOfADynastyData = await dynastyService.createAMemberOfADynasty(memberOfTheDynastyData)

        try {
            if(responseAMemberOfADynastyData.errorResponse) {
                toast.error(responseAMemberOfADynastyData.message)
            } else {
                setIsChecked(false)
                listAllMembersOfTheDynasty(search, currentPage, numberPerPage)
                toast("La création du notable a été éffectué avec succès")
    
            }
        } catch (error) {
            toast.error("Une érreur inattendue s'est produite lors de la creation du notable")
            console.log("Une érreur a été survenu lors de la création d'un => ", error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        listAllMembersOfTheDynasty(search, currentPage, numberPerPage);
    }, [search, currentPage, numberPerPage])

    const handleSearchRoyalPalace = (event) => {
        const keywords = event.target.value;
        setSearch(keywords);
        setCurrentPage(0);
    };

    const handleUpdateSubmit = async (values) => {

        const memberdynastyUpdateData = {
            name: values.name,
            description: values.description,
            description2: values.description2,
            number: values.number
        }

        const responseUpdateMemberOfDynasty = await dynastyService.updateAMemberOfDynasty(memberDynastyToUpdate.id,memberdynastyUpdateData);
        try {
            if (responseUpdateMemberOfDynasty?.errorResponse) {
                toast.error(responseUpdateMemberOfDynasty.message)
            } else {
                listAllMembersOfTheDynasty(search, currentPage, numberPerPage);
                closeModalToUpdate.current.click()
            }
        } catch (error) {
            toast.error("Une erreur inattendue s'est produite pendant la modification de ce clan")
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async(id) => {
        try {
            setLoading(true)
            const responseToDeleteAMemberOfDynasty = await dynastyService.deleteAMemberOfDynasty(id)
            if (responseToDeleteAMemberOfDynasty?.errorResponse) {
                toast.error(responseToDeleteAMemberOfDynasty.message)
            } else {
                toast.success("Ce membre de la dynastie a été Supprimé avec succès")
                listAllMembersOfTheDynasty(search, currentPage, numberPerPage);
                closeModalToDelete.current.click();
            }
            
        } catch (error) {
            console.error("Erreur lors de la suppression d'un membre de la dynastie:", error)
            toast.error("Une erreur inattendue s'est produite lors de la suppression d'un membre de la dynastie.")
        } finally {
            setLoading(false)
        }
    }

    //console.log('voir le type et idCommunity ===>> ', {id: communityId, type: type})
    return (
        <>
            <Header />
            <div class="form-element-area">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="form-element-list">
                                <div class="basic-tb-hd">
                                    <h2>Activer la création d'un membre de la dynastie</h2>
                                    <div class="TriSea-technologies-Switch">
                                        <input
                                            id="TriSeaPrimary"
                                            name="TriSea1"
                                            type="checkbox"
                                            checked={isChecked}
                                            onChange={handleToggle}
                                        />
                                        <label for="TriSeaPrimary" class="label-primary"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
            {isChecked ? (
                <div className="accordion-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="accordion-wn-wp mg-t-30">
                                    <div className="accordion-hd">
                                        <h2>Création d'un Notable</h2>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-6 col-xs-12">
                                            <div className="accordion-stn">
                                                <div className="panel-group" data-collapse-color="nk-indigo" id="accordionIndigo" role="tablist" aria-multiselectable="true">
                                                    <div className="panel panel-collapse notika-accrodion-cus">
                                                        <div className="panel-heading" role="tab">
                                                            <h4 className="panel-title">
                                                                <a className="collapsed" data-toggle="collapse" data-parent="#accordionIndigo" href="#accordionIndigo-two" aria-expanded="false">
                                                                    Cliquez ici pour voir le formulaire de création d'un membre de la dynastie
                                                                </a>
                                                            </h4>
                                                        </div>
                                                        <div id="accordionIndigo-two" className="collapse animated zoomInLeft" role="tabpanel">
                                                            <div className="panel-body">
                                                                <Formik
                                                                    initialValues={{
                                                                        name: '',
                                                                        photo: null, // Photo initialisée avec `null`
                                                                        description: '',
                                                                        description2: '',
                                                                        number: '',
                                                                    }}
                                                                    validationSchema={validationSchema}
                                                                    onSubmit={handleSubmit}
                                                                >
                                                                    {({ setFieldValue }) => (
                                                                        <Form>
                                                                            {/* Champ Name */}
                                                                            <div className="form-example-int">
                                                                                <div className="form-group">
                                                                                    <label htmlFor="name">Nom</label>
                                                                                    <div className="nk-int-st">
                                                                                        <Field
                                                                                            type="text"
                                                                                            name="name"
                                                                                            id="name"
                                                                                            className="form-control input-sm"
                                                                                            placeholder="Entrez le nom"
                                                                                        />
                                                                                        <ErrorMessage name="name" component="div" style={{ color: 'red' }} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            {/* Champ Photo */}
                                                                            <div className="form-example-int mg-t-15">
                                                                                <div className="form-group">
                                                                                    <label htmlFor="photo">Photo</label>
                                                                                    <div className="nk-int-st">
                                                                                        <input
                                                                                            type="file"
                                                                                            id="photo"
                                                                                            className="form-control input-sm"
                                                                                            onChange={(event) => setFieldValue('photo', event.target.files[0])}
                                                                                        />
                                                                                        <ErrorMessage name="photo" component="div" style={{ color: 'red' }} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {/* Champ Description */}
                                                                            <div className="form-example-int mg-t-15">
                                                                                <div className="form-group">
                                                                                    <label htmlFor="description">Description</label>
                                                                                    <div className="nk-int-st">
                                                                                        <Field
                                                                                            as="textarea"
                                                                                            name="description"
                                                                                            className="form-control input-sm"
                                                                                            placeholder="Veuillez saisir la description"
                                                                                        />
                                                                                        <ErrorMessage name="description" component="div" style={{ color: 'red' }} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {/* Champ Description2 */}
                                                                            <div className="form-example-int mg-t-15">
                                                                                <div className="form-group">
                                                                                    <label htmlFor="description2">Description 2</label>
                                                                                    <div className="nk-int-st">
                                                                                        <Field
                                                                                            as="textarea"
                                                                                            name="description2"
                                                                                            className="form-control input-sm"
                                                                                            placeholder="Veuillez saisir la deuxième description"
                                                                                        />
                                                                                        <ErrorMessage name="description2" component="div" style={{ color: 'red' }} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {/* Champ Job */}
                                                                            <div className="form-example-int mg-t-15">
                                                                                <div className="form-group">
                                                                                    <label htmlFor="job">Numéro</label>
                                                                                    <div className="nk-int-st">
                                                                                        <Field
                                                                                            type="text"
                                                                                            name="number"
                                                                                            id="number"
                                                                                            className="form-control input-sm"
                                                                                            placeholder="Entrez le métier"
                                                                                        />
                                                                                        <ErrorMessage name="job" component="div" style={{ color: 'red' }} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {/* Bouton de soumission */}
                                                                            <div className="form-example-int mg-t-15">
                                                                                <button type="submit" className="btn btn-success notika-btn-success">
                                                                                    Créer un membre de la dynastie
                                                                                </button>
                                                                            </div>
                                                                        </Form>
                                                                    )}
                                                                </Formik>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <div className="accordion-area">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="accordion-wn-wp mg-t-30">
                                        <div className="accordion-hd">
                                            <h2>Filtre par la recherche et par le nombre d'élément</h2>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-6 col-xs-12">
                                                <div className="accordion-stn" >
                                                    <div className="col-6">
                                                        <div class="dataTables_length" id="data-table-basic_length">
                                                            <label>
                                                                Show 
                                                                <select name="data-table-basic_length" aria-controls="data-table-basic" class="">
                                                                    <option value="10">10</option>
                                                                    <option value="25">25</option>
                                                                    <option value="50">50</option>
                                                                    <option value="100">100</option>
                                                                </select> 
                                                                entries
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className='col-6'>
                                                        <div id="data-table-basic_filter" class="dataTables_filter">
                                                            <label>Rechercher:
                                                                <input 
                                                                    type="search" 
                                                                    className="" 
                                                                    placeholder="Titre, description" 
                                                                    aria-controls="data-table-basic"
                                                                    onChange={handleSearchRoyalPalace}
                                                                    />
                                                            </label>
                                                        </div>
                                                    </div>    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row timeline">
                            {notables.map((member, index) => (
                                <div className={`col-md-6 timeline-item ${index % 2 === 0 ? 'left' : 'right'}`} key={member.id}>
                                    <div className="thumbnail">
                                        <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <img src={`${process.env.REACT_APP_SERVER}${member?.photo}`} style={{ width: "100%", height: "500px", objectFit: "cover" }} alt={`Image de ${member.name}`} />
                                        </div>
                                        <div className="caption" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                            <div>
                                                <h1>{member.name}</h1>
                                                <p>{member.description}</p>
                                            </div>
                                            <div className="button-container">
                                                <button 
                                                    className="btn btn-success btn-block" 
                                                    role="button" 
                                                    data-toggle="modal" 
                                                    data-target="#myModalToDescribeAMemberDynasty"
                                                    onClick={() => setMemberDynastyToUpdate(member)}
                                                >
                                                    Description
                                                </button>
                                                <button 
                                                    className="btn btn-warning btn-block" 
                                                    data-toggle="modal" 
                                                    data-target="#myModalToUpdateNotable" 
                                                    onClick={() => setMemberDynastyToUpdate(member)}
                                                >
                                                    Modifier
                                                </button>
                                                <button 
                                                    className="btn btn-danger btn-block" 
                                                    role="button" 
                                                    data-toggle="modal" 
                                                    data-target="#confirmDeleteNotableModal"
                                                    onClick={() => setMemberDynastyToUpdate(member)}
                                                >
                                                    Supprimer
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            {/* <div className="timeline-line"></div> Ligne verticale du timeline */}
                        </div>
                    </div>             
                </>
            )}
            <div class="modal fade" id="myModaltwo" role="dialog">
                <div class="modal-dialog modal-large">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div class="modal-body">
                            <h2>Modal title</h2>
                            <p>Curabitur blandit mollis lacus. Nulla sit amet est. Suspendisse nisl elit, rhoncus eget, elementum ac, condimentum eget, diam. Donec mi odio, faucibus at, scelerisque quis, convallis in, nisi. Cras sagittis.</p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-default" data-dismiss="modal">Save changes</button>
                            <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="myModalthree" role="dialog">
                <div class="modal-dialog modal-large">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div class="modal-body">
                            <h2>Modal title</h2>
                            <p>Curabitur blandit mollis lacus. Nulla sit amet est. Suspendisse nisl elit, rhoncus eget, elementum ac, condimentum eget, diam. Donec mi odio, faucibus at, scelerisque quis, convallis in, nisi. Cras sagittis.</p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-default" data-dismiss="modal">Save changes</button>
                            <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="myModalToDescribeAMemberDynasty" role="dialog">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal">
                                &times;
                            </button>
                            <h4 className="modal-title">Description d'un Notable</h4>
                        </div>
                        <div className="modal-body">
                            {/* Nom */}
                            <div className="form-group">
                                <label><strong>Nom :</strong></label>
                                <p>{memberDynastyToUpdate?.name}</p>
                            </div>
                            {/* Description */}
                            <div className="form-group">
                                <label><strong>Description :</strong></label>
                                <p>{memberDynastyToUpdate?.description}</p>
                            </div>
                            {/* Description2 */}
                            <div className="form-group">
                                <label><strong>Description Additionnelle :</strong></label>
                                <p>{memberDynastyToUpdate?.description2}</p>
                            </div>
                            {/* Numéro */}
                            <div className="form-group">
                                <label><strong>Numéro :</strong></label>
                                <p>{memberDynastyToUpdate?.number}</p>
                            </div>
                            {/* Photo */}
                            <div className="form-group">
                                <label><strong>Photo :</strong></label>
                                <div style={{ height: '500px', overflow: 'hidden' }}>
                                    <img 
                                        src={process.env.REACT_APP_SERVER+memberDynastyToUpdate?.photo} 
                                        alt="Notable" 
                                        className="img-responsive" 
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default" data-dismiss="modal">
                                Fermer
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* Modal to update notable */}
            <div className="modal fade" id="myModalToUpdateNotable" role="dialog">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal">
                                &times;
                            </button>
                            <h4 className="modal-title">Modifier un Notable</h4>
                        </div>
                        <div className="modal-body">
                            <Formik
                                enableReinitialize={true}
                                initialValues={{
                                    name: memberDynastyToUpdate?.name || '', // Initialiser avec les valeurs actuelles ou laisser vide
                                    description: memberDynastyToUpdate?.description || '',
                                    description2: memberDynastyToUpdate?.description2 || '',
                                    number: memberDynastyToUpdate?.number || '',
                                }}
                                validationSchema={Yup.object({
                                    name: Yup.string().required('Le nom est requis'),
                                    // photo: Yup.mixed().required('La photo est requise'),
                                    description: Yup.string().required('La description est requise'),
                                    description2: Yup.string(),
                                    number: Yup.number().required('Le clan est requis'),
                                })}
                                onSubmit={handleUpdateSubmit}
                            >
                                {({ setFieldValue }) => (
                                    <Form className="form-example-wrap">
                                        {/* Champ Name */}
                                        <div className="form-group">
                                            <label>Nom</label>
                                            <Field
                                                type="text"
                                                name="name"
                                                className="form-control input-sm"
                                                placeholder="Entrez le nom"
                                            />
                                            <ErrorMessage
                                                name="name"
                                                component="div"
                                                className="text-danger"
                                            />
                                        </div>
                                        {/* Champ Titre */}
                                        <div className="form-group">
                                            <label>Titre</label>
                                            <Field
                                                type="text"
                                                name="titre"
                                                className="form-control input-sm"
                                                placeholder="Entrez le titre"
                                            />
                                            <ErrorMessage
                                                name="titre"
                                                component="div"
                                                className="text-danger"
                                            />
                                        </div>
                                        {/* Champ Description */}
                                        <div className="form-group">
                                            <label>Description</label>
                                            <Field
                                                as="textarea"
                                                name="description"
                                                className="form-control input-sm"
                                                placeholder="Veuillez saisir la description"
                                            />
                                            <ErrorMessage
                                                name="description"
                                                component="div"
                                                className="text-danger"
                                            />
                                        </div>

                                        {/* Champ Description2 */}
                                        <div className="form-group">
                                            <label>Description Additionnelle</label>
                                            <Field
                                                as="textarea"
                                                name="description2"
                                                className="form-control input-sm"
                                                placeholder="Entrez des informations supplémentaires"
                                            />
                                            <ErrorMessage
                                                name="description2"
                                                component="div"
                                                className="text-danger"
                                            />
                                        </div>
                                        {/* Champ Clan ID */}
                                        <div className="form-group">
                                            <label>Numéro</label>
                                            <Field
                                                type="text"
                                                name="number"
                                                className="form-control input-sm"
                                                placeholder="Entrez le titre"
                                            />
                                            <ErrorMessage
                                                name="number"
                                                component="div"
                                                className="text-danger"
                                            />
                                        </div>
                                        {/* Bouton Soumettre */}
                                        <div className="form-group">
                                            <button
                                                type="submit"
                                                className="btn btn-success notika-btn-success"
                                            >
                                                Modifier
                                            </button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                        <div className="modal-footer">
                            <button 
                                type="button" 
                                className="btn btn-default" 
                                data-dismiss="modal"
                                ref={closeModalToUpdate}
                            >
                                Fermer
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Modal de confirmation de suppression --> */}
            <div className="modal fade" id="confirmDeleteNotableModal" role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                            <h4 className="modal-title">Confirmation de Suppression</h4>
                        </div>
                        <div className="modal-body">
                            <p>Êtes-vous sûr de vouloir supprimer cet élément ? Cette action est irréversible.</p>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-default"
                                data-dismiss="modal"
                                ref={closeModalToDelete}
                            >
                                Annuler
                            </button>
                            <button
                                type="button"
                                className="btn btn-danger"
                                onClick={() => handleDelete(memberDynastyToUpdate.id)}
                            >
                                Confirmer la Suppression
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Dynasty;