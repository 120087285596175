import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import Header from '../components/Header';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import communityService from '../services/communityService';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getIdAndtypeCommunity } from '../features/postInCommunity/postInCommunitySlice';
import ReactPaginate from 'react-paginate';
import leaderService from '../services/leaderService';
import { getALeader } from '../features/leader/leaderSlice';


function Leaders(props) {
    const dispatch = useDispatch()
    const [isChecked, setIsChecked] = useState(false);
    const [loading, setLoading] = useState(false)
    const [CulturalWealths, setCulturalWealths] = useState([]);
    const [totalNumberOfPage, setTotalNumberOfPage] = useState(null);
    const [search, setSearch] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [numberPerPage, setNumberPerPage] = useState(10);
    const closeModalToDelete = useRef(null);
    const closeModalToAddPhotoAndVideo = useRef(null);
    const [traditionalDanseUpdateOrDelete, setTraditionalDanseUpdateOrDelete] = useState(null);
    const [isCheckedVideo, setIsCheckedVideo] = useState(false);
    const [traditionalDanseUpdate, setTraditionalDanseUpdate] = useState(null);
    const closeModalToUpdate = useRef(null);
    const closeModalToUpdateLeader = useRef(null);
    const navigate = useNavigate()
    const [leaders, setLeaders] = useState([]);
    const [leaderProfil, setLeaderProfil] = useState(null);

    // Fonction pour gérer le changement de l'état du toggle
    const handleToggle = () => {
        setIsChecked(!isChecked);
    };

    const handleToggleVideo = () => {
        setIsCheckedVideo(!isCheckedVideo)
    }

    const validationLeaderSchema = Yup.object().shape({
        name: Yup.string()
          .required('Le champ "Nom" est obligatoire.')
          .min(2, 'Le nom doit contenir au moins 2 caractères.')
          .max(50, 'Le nom ne doit pas dépasser 50 caractères.'),
        description: Yup.string()
          .required('Le champ "Description" est obligatoire.')
          .min(10, 'La description doit contenir au moins 10 caractères.')
          .max(500, 'La description ne doit pas dépasser 500 caractères.'),
        activity: Yup.string()
          .required('Le champ "Activité" est obligatoire.')
          .min(5, 'L’activité doit contenir au moins 5 caractères.')
          .max(300, 'L’activité ne doit pas dépasser 300 caractères.'),
      });

      const handleLeaderSubmit = async(values, { resetForm }) => {
        setLoading(true);
        alert(JSON.stringify(values, null, 4))
        const leaderData = {
            name: values.name,
            activity: values.description,
            description: values.description,
        }
        const responseLeader = await leaderService.createALeader(leaderData)
        try {
            if(responseLeader.errorResponse) {
                toast.error(responseLeader.message)
            } else {
                setIsChecked(false)
                listLeaders(search, currentPage, numberPerPage)
                toast("La création du leader a été éffectué avec succès")
    
            }
        } catch (error) {
            toast.error("Une érreur inattendue s'est produite lors de la creation du leader")
            console.log("Une érreur a été survenu lors de la création d'un => ", error)
        } finally {
            setLoading(false)
        }
      };

    // const listTraditionalDanse = async(search, page, numberPerPage) => {
    //     const data = {};
    //     data.search = search;
    //     data.page = page+1; 
    //     data.numberPerPage = numberPerPage;
    //     data.type = "leader"
    //     setLoading(true);
    //     try {
    //         const responseDiaporaCommunity = await culturalWealthService.listCulturalWealths(data)
    //         if (responseDiaporaCommunity.errorResponse) {
    //             toast.error(responseDiaporaCommunity.message)
    //         } else {
    //             setCulturalWealths(responseDiaporaCommunity.results);
    //             setTotalNumberOfPage(responseDiaporaCommunity.numberOfPages)
    //         }
    //     } catch (error) {
    //         toast.error("Une erreur est obtenu pendant l'obtension des diaspora")
    //     } finally {
    //         setLoading(false)
    //     }
    // }

    const listLeaders = async(search, currentPage, numberPerPage) => {
        const data = {};
        data.search = search;
        data.currentPage = currentPage;
        data.numberPerPage = numberPerPage;
        setLoading(true);
        try {
            const responseToListAllLeaders = await leaderService.getLeaders(data)
            if (responseToListAllLeaders.errorResponse) {
                toast.error(responseToListAllLeaders.message)
            } else {
                setLeaders(responseToListAllLeaders.results);
                setTotalNumberOfPage(responseToListAllLeaders.numberOfPages)
            }
        } catch (error) {
            toast.error("Une erreur est obtenu pendant l'obtension des diaspora")
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        listLeaders(search, numberPerPage)
    }, [search, currentPage, numberPerPage])

    // useEffect(() => {
    //     listTraditionalDanse(search, currentPage, numberPerPage)
    // }, [search, currentPage, numberPerPage]);

    // const deletePost = async (id) => {
    //     try {
    //         setLoading(true)
    //         const response = await postService.deletePost(id)
    //         if (response?.errorResponse) {
    //             toast.error(response.message)
    //         } else {
    //             toast.success("Le post a été Supprimé avec succès")
    //             console.log("Suppression avant =>>")
    //             listTraditionalDanse(search, currentPage, numberPerPage);
    //             console.log("Suppression ensuite =>>")
    //             closeModalToDelete.current.click();
    //         }
            
    //     } catch (error) {
    //         console.error("Erreur lors de la suppression du post chambre:", error)
    //         toast.error("Une erreur inattendue s'est produite lors de la suppression de la chambre.")
    //     } finally {
    //         setLoading(false)
    //     }
    // }

    // Définir le schéma de validation avec Yup
    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Le nom est requis"),
        description: Yup.string().required("La description est requise"),
        activity: Yup.string().required("Entrer l'activité du leader")
    });

    const validationPhotoSchema = Yup.object({
        photo: Yup.mixed().required('La photo est requise'),
      });

    // const handleSubmit = async (values) => {
    //     setLoading(true);
    //     const postData = {
    //         title: values.title,
    //         description: values.description,
    //         message: values.message,
    //         type: values.type,
    //     }
    //     const responsePost = await postService.createPost(postData)
    //     try {
    //         if(responsePost.errorResponse) {
    //             toast.error(responsePost.message)
    //         } else {
    //             setIsChecked(false)
    //             listTraditionalDanse(search, currentPage, numberPerPage)
    //             toast("La création de type danse traditionnelle a été éffectué avec succès")
    
    //         }
    //     } catch (error) {
    //         toast.error("Une érreur inattendue s'est produite lors de la creation du post")
    //         console.log("Une érreur a été survenu lors de la création d'un => ", error)
    //     } finally {
    //         setLoading(false)
    //     }
    // }

    // const handlePhotoSubmit = async (values) => {
    //     const formData = new FormData()
    //     formData.append('photo', values.photo)
    //     formData.append('post_id', traditionalDanseUpdateOrDelete.id);

    //     const responseAddPhoto = await postService.addPhotoPost(formData)
    //     try {
    //         if (responseAddPhoto.errorResponse) {
    //             toast.error(responseAddPhoto.message)
    //         } else {
    //             toast("L'ajout de la photo a été éffectué avec succès");
    //             closeModalToAddPhotoAndVideo.current.click()
    //             listTraditionalDanse(search, currentPage, numberPerPage)
    //         }
    //     } catch (error) {
    //         toast.error("Une érreur inattendue s'est produite lors de l'ajout de ce video")
    //     }
        
    // };

    // const handleVideoSubmit = async (values) => {
    //     const formData = new FormData()
    //     formData.append('video', values.video)
    //     formData.append('post_id', traditionalDanseUpdateOrDelete.id);
    //     formData.append('description', values.description);
    //     formData.append('title', values.title);
    //     formData.append('miniature', values.miniature);

    //     const responseAddVideo = await postService.addVideoPost(formData);

    //     try {
    //         if (responseAddVideo.errorResponse) {
    //             toast.error(responseAddVideo.message)
    //         } else {
    //             toast("L'ajout de la photo a été éffectué avec succès");
    //             closeModalToAddPhotoAndVideo.current.click()
    //             listTraditionalDanse(search, currentPage, numberPerPage)
    //         }
    //     } catch (error) {
    //         toast.error("Une érreur est survenue lors de l'ajout de cette video")
    //     }
        
    // };

    const validationVideoSchema = Yup.object().shape({
        video: Yup.string()
            .required("Le lien de la vidéo est requis")
            .matches(
                /^(https?\:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$/,
                "Veuillez entrer un lien YouTube valide"
            ),
        description: Yup.string()
            .required("La description est requise")
            .min(10, "La description doit contenir au moins 10 caractères"),
        title: Yup.string()
            .required("Le titre est requis")
            .min(3, "Le titre doit contenir au moins 3 caractères"),
        miniature: Yup.mixed()
            .required("La miniature est requise")
            .test(
                "fileFormat",
                "Seules les images jpg/jpeg sont autorisées",
                (value) => value && ["image/jpeg", "image/jpg"].includes(value.type)
            ),
    });

    const handleLeaderUpdateSubmit = async (values) => {
        const postData = {
            name: values.name,
            description: values.description,
            activity: values.activity,
        };

        const responsePost = await leaderService.updateLeader(leaderProfil.id, postData)
        try {
            if(responsePost.errorResponse) {
                toast.error(responsePost.message)
            } else {
                setIsChecked(false)
                listLeaders(search, currentPage, numberPerPage)
                toast("La création de type danse traditionnelle a été éffectué avec succès")
                closeModalToUpdateLeader.current.click()
            }
        } catch (error) {
            toast.error("Une érreur inattendue s'est produite lors de la creation du post")
            console.log("Une érreur a été survenu lors de la création d'un => ", error)
        } finally {
            setLoading(false)
        }
    };

    const handleSearchAnObject = (event) => {
        const searchAnObject = event.target.value;
        setSearch(searchAnObject)
        setCurrentPage(0)
    };

    const handleChangeCurrentPage = (data) => {
        let currentPageu = data.selected;
        console.log('mon current ==>>> ', currentPageu)
        setCurrentPage(currentPageu)
        return ;
    
    };

    const handleClick = (leader) => {
        dispatch(getALeader({ leader: leader }));
        // Naviguer vers /posts/:nameCommunity1
        navigate(`/leader/${leader.name}/${leader.id}`);
    }

   
    return (
        <>
            <Header />  
            <div class="form-element-area">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="form-element-list">
                                <div class="basic-tb-hd">
                                    <h2>Activer la création d'un leader</h2>
                                    <div class="TriSea-technologies-Switch">
                                        <input
                                            id="TriSeaPrimary"
                                            name="TriSea1"
                                            type="checkbox"
                                            checked={isChecked}
                                            onChange={handleToggle}
                                        />
                                        <label for="TriSeaPrimary" class="label-primary"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
            {isChecked ? 
                (
                    <div class="accordion-area">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="accordion-wn-wp">
                                        <div class="accordion-hd">
                                            <h2>Formulaire de création d'un Leader</h2>
                                            {/* <p>Ici vous aurez la possibilité de créer une diapora en remplissant un formulaire. <code>Cliquer ici pour créer une diapora</code></p> */}
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-12 col-md-6 col-sm-12 col-xs-12">
                                                <div class="accordion-stn">
                                                    <div class="panel-group" data-collapse-color="nk-green" id="accordionGreen" role="tablist" aria-multiselectable="true">
                                                        <div class="panel panel-collapse notika-accrodion-cus">
                                                            <div class="panel-heading" role="tab">
                                                                <h4 class="panel-title">
                                                                    <a data-toggle="collapse" data-parent="#accordionGreen" href="#accordionGreen-one" aria-expanded="false">
                                                                            Cliquez ici pour créer un leader
                                                                        </a>
                                                                </h4>
                                                            </div>
                                                            <div id="accordionGreen-one" class="collapse in" role="tabpanel">
                                                                <div class="panel-body">
                                                                    {/* <!-- Form Examples area start--> */}
                                                                        {/* <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12"> */}
                                                                        <Formik
                                                                            initialValues={{
                                                                                name: "",
                                                                                description: "",
                                                                                activity: "",
                                                                            }}
                                                                            validationSchema={validationSchema}
                                                                            onSubmit={handleLeaderSubmit}
                                                                        >
                                                                            {() => (
                                                                                <Form>
                                                                                {/* Champ Nom */}
                                                                                <div className="form-example-int">
                                                                                    <div className="form-group">
                                                                                        <label htmlFor="nom">Nom</label>
                                                                                        <div className="nk-int-st">
                                                                                            <Field
                                                                                                type="text"
                                                                                                name="name"
                                                                                                id="nom"
                                                                                                className="form-control input-sm"
                                                                                                placeholder="Entrez le nom"
                                                                                            />
                                                                                            <ErrorMessage name="nom" component="div" style={{ color: 'red' }} />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="form-example-int">
                                                                                    <div className="form-group">
                                                                                        <label htmlFor="activity">Activité</label>
                                                                                        <div className="nk-int-st">
                                                                                            <Field
                                                                                                type="text"
                                                                                                name="activity"
                                                                                                id="nom"
                                                                                                className="form-control input-sm"
                                                                                                placeholder="Entrez le nom"
                                                                                            />
                                                                                            <ErrorMessage name="nom" component="div" style={{ color: 'red' }} />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                {/* Champ Description */}
                                                                                <div className="form-example-int mg-t-15">
                                                                                    <div className="form-group">
                                                                                        <label htmlFor="description">Description</label>
                                                                                        <div className="nk-int-st">
                                                                                            <Field
                                                                                                as="textarea"
                                                                                                name="description"
                                                                                                className="form-control input-sm"
                                                                                                placeholder="Veuillez saisir la description du concernant cette diapora"
                                                                                            />
                                                                                            <ErrorMessage name="description" component="div" style={{ color: 'red' }} />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                            
                                                                                {/* Bouton de soumission */}
                                                                                <div className="form-example-int mg-t-15">
                                                                                    <button type="submit" className="btn btn-success notika-btn-success">
                                                                                        Créer un Leader
                                                                                    </button>
                                                                                </div>
                                                                                </Form>
                                                                            )}
                                                                        </Formik>
                                                                    </div>             
                                                                {/* <!-- Form Examples area End--> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) :
                (
                    <div className="inbox-area" style={{marginTop: "15px"}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="inbox-text-list sm-res-mg-t-30">
                                        <div className="form-group">
                                            <div className="nk-int-st search-input search-overt">
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    placeholder="Entrer le nom d'un leader..."
                                                    // onChange={handleSearchDiaspora} 
                                                />
                                            </div>
                                        </div>
                                        <div className="table-responsive">
                                            <table className="table table-hover table-inbox">
                                                <tbody>
                                                    {leaders.map((leader, index) => (
                                                        <tr key={index} className="active">
                                                            <td>
                                                                <a role='button' onClick={() => handleClick(leader)}>
                                                                    {leader.id}
                                                                </a>
                                                            </td>
                                                            <td>
                                                                <a role='button' onClick={() => handleClick(leader)}>
                                                                {leader.name.length > 10 ? `${leader.name.slice(0, 10)}...` : leader.name}
                                                                </a>
                                                            </td>
                                                            <td>
                                                                <a role='button' onClick={() => handleClick(leader)}>
                                                                    {leader.activity.length > 10 ? `${leader.activity.slice(0, 10)}...` : leader.activity}
                                                                </a>
                                                            </td>
                                                            <td>
                                                                <a role='button' onClick={() => handleClick(leader)}>
                                                                    {leader.description.length > 10 ? `${leader.description.slice(0, 10)}...` : leader.description}
                                                                </a>
                                                            </td>
                                                            <td>
                                                                <button 
                                                                    type="button" 
                                                                    className="btn btn-info waves-effect" 
                                                                    data-toggle="modal" 
                                                                    data-target="#myModalProfil"
                                                                    onClick={() => setLeaderProfil(leader)}
                                                                >
                                                                    Profil
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <a 
                                                                    className="btn btn-primary notika-btn-primary waves-effect"
                                                                    role="button"
                                                                    data-toggle="modal" 
                                                                    data-target="#myModalUpdate"
                                                                    onClick={() => setLeaderProfil(leader)}
                                                                >
                                                                    Modifier
                                                                </a>
                                                            </td>
                                                            <td>
                                                                <a 
                                                                    className="btn btn-danger notika-btn-primary waves-effect"
                                                                    role="button"
                                                                    data-toggle="modal" 
                                                                    data-target="#myModalToDeleteDiaspora"
                                                                    onClick={() => setLeaderProfil(leader)}
                                                                >
                                                                    Supprimer
                                                                </a>
                                                            </td>
                                                            
                                                        </tr>
                                                    ))} 
                                                </tbody>
                                            </table>
                                            <ReactPaginate
                                                key={currentPage}
                                                initialPage={currentPage}
                                                previousLabel={"<"}
                                                nextLabel={">"}
                                                breakLabel={"..."}
                                                pageCount={totalNumberOfPage}
                                                // marginPagesDisplayed={2}
                                                // pageRangeDisplayed={3}
                                                onPageChange={handleChangeCurrentPage}
                                                containerClassName={"pagination justify-content-center"}
                                                pageClassName={"page-item"}
                                                pageLinkClassName={"page-link"}
                                                previousClassName={"page-item"}
                                                previousLinkClassName={"page-link"}
                                                nextClassName={"page-item"}
                                                nextLinkClassName={"page-link"}
                                                breakClassName={"page-item"}
                                                breakLinkClassName={"page-link"}
                                                activeLinkClassName={"active"}
                                                activeClassName={"active"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            {/* Modal to see profil diaspora */}
            <div class="modal fade" id="myModalProfil" tabindex="-1" role="dialog" aria-labelledby="myModalProfilLabel">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <h4 class="modal-title" id="myModalProfilLabel">Informations</h4>
                        </div>
                        <div class="modal-body">
                            <p><strong>Nom :</strong> <span id="modal-name">{leaderProfil?.name}</span></p>
                            <p><strong>Activité :</strong> <span id="modal-type">{leaderProfil?.activity}</span></p>
                            <p><strong>Description :</strong> <span id="modal-description">{leaderProfil?.description}</span></p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-default" data-dismiss="modal">Fermer</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* Modal to update a diaspora */}
            <div className="modal fade" id="myModalUpdate" role="dialog">
                <div className="modal-dialog modal-large">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal">
                            &times;
                            </button>
                        </div>
                        <div className="modal-body">
                            <h2>Modifier un Leader</h2>
                            <Formik
                                enableReinitialize={true}
                                initialValues={{
                                    name: leaderProfil?.name || '',
                                    description: leaderProfil?.description || '',
                                    activity: leaderProfil?.activity || '',
                                }}
                                validationSchema={Yup.object({
                                    name: Yup.string().required('Le nom est requis'),
                                    description: Yup.string().required('La description est requise'),
                                    activity: Yup.string().required('Le type est requis'),
                                })}
                                onSubmit={handleLeaderUpdateSubmit}
                            >
                            {() => (
                                <Form className="form-example-wrap">
                                    <div className="form-example-int">
                                        <div className="form-group">
                                            <label>Nom</label>
                                            <div className="nk-int-st">
                                                <Field
                                                    type="text"
                                                    name="name"
                                                    className="form-control input-sm"
                                                    placeholder="Entrez le nom"
                                                />
                                                <ErrorMessage
                                                    name="name"
                                                    component="div"
                                                    className="text-danger"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-example-int">
                                        <div className="form-group">
                                            <label>Activité</label>
                                            <div className="nk-int-st">
                                                <Field
                                                    type="text"
                                                    name="activity"
                                                    className="form-control input-sm"
                                                    placeholder="Entrez le nom"
                                                />
                                                <ErrorMessage
                                                    name="activity"
                                                    component="div"
                                                    className="text-danger"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                
                                    <div className="form-example-int">
                                        <div className="form-group">
                                            <label>Description</label>
                                            <div className="nk-int-st">
                                                <Field
                                                as="textarea"
                                                name="description"
                                                className="form-control input-sm"
                                                placeholder="Veuillez saisir la description"
                                                />
                                                <ErrorMessage
                                                name="description"
                                                component="div"
                                                className="text-danger"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                <div className="form-example-int mg-t-15">
                                    <button
                                        type="submit"
                                        className="btn btn-success notika-btn-success"
                                    >
                                        Enregistrer
                                    </button>
                                </div>
                                </Form>
                            )}
                            </Formik>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-default"
                                data-dismiss="modal"
                                ref={closeModalToUpdateLeader}
                            >
                                Fermer
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* Modal to delete */}
            <div class="modal animated rubberBand" id="myModalToDeleteDiaspora" role="dialog">
                <div class="modal-dialog modals-default">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button"  class="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div class="modal-body">
                            <h2>Suppression de la communauté {leaderProfil?.name}</h2>
                            <p>Voulez-vous vraiment supprimer cette communauté ?</p>
                        </div>
                        <div class="modal-footer">
                            <button 
                                type="button" 
                                class="btn brn-danger notika-btn-danger" 
                                data-dismiss="modal" 
                                // onClick={() => deleteADiaspora(leaderProfil)}
                            >
                                Supprimer
                            </button>
                            <button 
                                type="button" 
                                class="btn btn-default" 
                                data-dismiss="modal"
                                ref={closeModalToUpdate}
                            >
                                Annuler
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Leaders;