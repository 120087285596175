import React, { useState, useEffect, useRef } from 'react';
import Header from '../components/Header';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import programBroadcastService from '../services/programBroadcastService';
import { toast } from 'react-toastify';
import podcastService from '../services/podcastService';
import ReactPlayer from "react-player";



function Podcasts(props) {
    // État pour gérer l'état du toggle
    const [isChecked, setIsChecked] = useState(false);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [numberPerPage, setNumberPerPage] = useState(10);
    const [podcasts, setPodcasts] = useState([]);
    const [totalNumberOfPage, setTotalNumberOfPage] = useState(null);
    const [podcastToDelete ,setPodcastToDelele] = useState(null);
    const closeModalToDeletePodcast = useRef(null);
    const closeModalToUpdatePodcast = useRef(null)

    // Fonction pour gérer le changement de l'état du toggle
    const handleToggle = () => {
        setIsChecked(!isChecked);
    };

    const handleSubmit = async (values) => {
        setLoading(true);
        const data = {};
        data.title = values.title;
        data.message = values.message;
        data.pathAudio = values.pathAudio;
        const responseProgramBroadcast = await podcastService.createAPodcat(data)
        try {
            if(responseProgramBroadcast.errorResponse) {
               toast.error(responseProgramBroadcast.message)
            } else {
                setIsChecked(false)
                listPodcasts(search, currentPage, numberPerPage)
                toast("La création de l'emission a été éffectué avec succès")
    
            }
        } catch (error) {
            toast.error("Une érreur inattendue s'est produite lors de la creation de l'émission")
            console.log("Une érreur a été survenu lors de la création d'un => ", error)
        } finally {
            setLoading(false)
        }
    };

    const listPodcasts = async(search, page, numberPerPage) => {
        const data = {};
        data.search = search;
        data.page = page; 
        data.numberPerPage = numberPerPage;
        setLoading(true);
        try {
            const responsePodcasts = await podcastService.listPodcast(data)
            if (responsePodcasts.errorResponse) {
                toast.error(responsePodcasts.message)
            } else {
                setPodcasts(responsePodcasts.results);
                setTotalNumberOfPage(responsePodcasts.totalResult)
            }
        } catch (error) {
            toast.error("Une erreur est obtenu pendant l'obtension des diaspora")
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        listPodcasts(search, currentPage, numberPerPage)
    }, [search, currentPage, numberPerPage]);

    const deletePodCast = async (id) => {
        try {
            setLoading(true)
            const response = await podcastService.deletePodcast(id)
            if (response.errorResponse) {
                toast.error(response.message)
            } else {
                toast.success("Le podcast a été Supprimé avec succès")
                listPodcasts(search, currentPage, numberPerPage);
                closeModalToDeletePodcast.current.click()
            }
            
        } catch (error) {
            console.error("Erreur lors de la suppression du podcast:", error)
            toast.error("Une erreur inattendue s'est produite lors de la suppression du podcast.")
        } finally {
            setLoading(false)
        }
    }

    const handleLeaderUpdateSubmit = async (values) => {
        const postData = {
            title: values.title,
            message: values.message,
            pathAudio: values.pathAudio,
        };

        const responsePost = await podcastService.updatePodcast(podcastToDelete.id, postData)
        try {
            if(responsePost.errorResponse) {
                toast.error(responsePost.message)
            } else {
                setIsChecked(false)
                listPodcasts(search, currentPage, numberPerPage)
                toast("La modification du podcast a été éffectué avec succès")
                closeModalToUpdatePodcast.current.click()
            }
        } catch (error) {
            toast.error("Une érreur inattendue s'est produite lors de la modification du podcast")
            console.log("Une érreur a été survenu lors de la création d'un => ", error)
        } finally {
            setLoading(false)
        }
    };

    return (
        <>
            <Header />
            <div class="form-element-area">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="form-element-list">
                                <div class="basic-tb-hd">
                                    <h2>Activer la création d'un Podcast</h2>
                                    <div class="TriSea-technologies-Switch">
                                        <input
                                            id="TriSeaPrimary"
                                            name="TriSea1"
                                            type="checkbox"
                                            checked={isChecked}
                                            onChange={handleToggle}
                                        />
                                        <label for="TriSeaPrimary" class="label-primary"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
            {isChecked ? (
                <div className="container mt-3">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-6 col-xs-12">
                            <div className="accordion-stn">
                                <div className="panel-group" data-collapse-color="nk-indigo" id="accordionIndigo" role="tablist" aria-multiselectable="true">
                                    <div className="panel panel-collapse notika-accrodion-cus">
                                        <div className="panel-heading" role="tab">
                                            <h4 className="panel-title">
                                                <a className="collapsed" data-toggle="collapse" data-parent="#accordionIndigo" href="#accordionIndigo-two" aria-expanded="false">
                                                    Cliquez ici pour voir le formulaire du podcast
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="accordionIndigo-two" className="collapse animated zoomInLeft" role="tabpanel">
                                            <div className="panel-body">
                                                <Formik
                                                    initialValues={{ title: '', message: '', pathAudio: '' }}
                                                    validationSchema={Yup.object().shape({
                                                        title: Yup.string().required('Title is required'),
                                                        message: Yup.string().required('Message is required'),
                                                        pathAudio: Yup.string()
                                                            .url('Audio must be a valid YouTube URL')
                                                            .required('Audio link is required')
                                                    })}
                                                    onSubmit={handleSubmit}
                                                >
                                                    {() => (
                                                        <Form className="form-example-wrap">
                                                            <div className="form-example-int">
                                                                <div className="form-group">
                                                                    <label>Title</label>
                                                                    <div className="nk-int-st">
                                                                        <Field
                                                                            type="text"
                                                                            name="title"
                                                                            className="form-control input-sm"
                                                                            placeholder="Enter Title"
                                                                        />
                                                                        <ErrorMessage name="title" component="div" className="text-danger" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="form-example-int">
                                                                <div className="form-group">
                                                                    <label>Message</label>
                                                                    <div className="nk-int-st">
                                                                        <Field
                                                                            as="textarea"
                                                                            name="message"
                                                                            className="form-control input-sm"
                                                                            placeholder="Enter your message"
                                                                        />
                                                                        <ErrorMessage name="message" component="div" className="text-danger" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="form-example-int">
                                                                <div className="form-group">
                                                                    <label>Audio Link (YouTube URL)</label>
                                                                    <div className="nk-int-st">
                                                                        <Field
                                                                            type="text"
                                                                            name="pathAudio"
                                                                            className="form-control input-sm"
                                                                            placeholder="Enter YouTube audio link"
                                                                        />
                                                                        <ErrorMessage name="audio" component="div" className="text-danger" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="form-example-int mg-t-15">
                                                                <button type="submit" className="btn btn-success notika-btn-success">
                                                                    Cliquez ici pour créer un podcast
                                                                </button>
                                                            </div>
                                                        </Form>
                                                    )}
                                                </Formik>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="container" style={{marginTop: "4px"}} >
                    <div class="row">
                        {podcasts?.map((podcast, index) => (
                            <div key={index} className="col-md-6">
                            <div className="panel panel-primary">
                                <div className="panel-heading text-center">
                                    <h3 className="panel-title">
                                        <i className="glyphicon glyphicon-music"></i> {podcast.title}
                                    </h3>
                                </div>
                                <div className="panel-body text-center">
                                    <div className="audio-player-wrapper">
                                        <ReactPlayer
                                            url={podcast.pathAudio}
                                            controls
                                            width="100%"
                                            height="60px" // Adapté pour audio uniquement
                                        />
                                    </div>
                                    <p className="text-muted" style={{ marginTop: "10px" }}>
                                        Écoutez votre audio préféré ici !
                                    </p>
                                </div>
                                <div className="panel-footer">
                                    <div className="row">
                                        <div className="col-xs-4 text-center">
                                            <button 
                                                className="btn btn-info btn-block"
                                                data-toggle="modal"
                                                data-target="#myModalToUpdatePodcast"
                                                onClick={() => setPodcastToDelele(podcast)}
                                            >
                                                <i className="glyphicon glyphicon-pencil"></i> Modifier
                                            </button>
                                        </div>
                                        <div className="col-xs-4 text-center">
                                            <button 
                                                className="btn btn-danger btn-block"
                                                data-toggle="modal"
                                                data-target="#myModalToDeletePodcast"
                                                onClick={() => setPodcastToDelele(podcast)}
                                            >
                                                <i className="glyphicon glyphicon-trash"></i> Supprimer
                                            </button>
                                        </div>
                                        <div className="col-xs-4 text-center">
                                            <button 
                                                className="btn btn-primary btn-block"
                                                data-toggle="modal" 
                                                data-target="#myModalToSeeDetail"
                                                onClick={() => setPodcastToDelele(podcast)}
                                            >
                                                <i className="glyphicon glyphicon-info-sign"></i> Détails
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        ))}
                        
                    </div>
                </div>
            )}
            {/* Modal to delete */}
            <div class="modal animated rubberBand" id="myModalToDeletePodcast" role="dialog">
                <div class="modal-dialog modals-default">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button"  class="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div class="modal-body">
                            <h2>Suppression du  {podcastToDelete?.name}</h2>
                            <p>Voulez-vous vraiment supprimer cette communauté ?</p>
                        </div>
                        <div class="modal-footer">
                            <button 
                                type="button" 
                                class="btn brn-danger notika-btn-danger" 
                                data-dismiss="modal" 
                                onClick={() => deletePodCast(podcastToDelete.id)}
                            >
                                Supprimer
                            </button>
                            <button 
                                type="button" 
                                class="btn btn-default" 
                                data-dismiss="modal"
                                ref={closeModalToDeletePodcast}
                            >
                                Annuler
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* Modal to update a podcast */}
            <div className="modal fade" id="myModalToUpdatePodcast" role="dialog">
                <div className="modal-dialog modal-large">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal">
                            &times;
                            </button>
                        </div>
                        <div className="modal-body">
                            <h2>Modifier le Podcast</h2>
                            <Formik
                                enableReinitialize={true}
                                initialValues={{
                                    title: podcastToDelete?.title || '',
                                    message: podcastToDelete?.message || '',
                                    pathAudio: podcastToDelete?.pathAudio || '',
                                }}
                                validationSchema={Yup.object({
                                    title: Yup.string().required('Le titre est requis'),
                                    message: Yup.string().required('La message est requise'),
                                    pathAudio: Yup.string().required('Le path est requis'),
                                })}
                                onSubmit={handleLeaderUpdateSubmit}
                            >
                            {() => (
                                <Form className="form-example-wrap">
                                    <div className="form-example-int">
                                        <div className="form-group">
                                            <label>Titre</label>
                                            <div className="nk-int-st">
                                                <Field
                                                    type="text"
                                                    name="title"
                                                    className="form-control input-sm"
                                                    placeholder="Entrez le nom"
                                                />
                                                <ErrorMessage
                                                    name="title"
                                                    component="div"
                                                    className="text-danger"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-example-int">
                                        <div className="form-group">
                                            <label>Message</label>
                                            <div className="nk-int-st">
                                                <Field
                                                    type="text"
                                                    name="message"
                                                    className="form-control input-sm"
                                                    placeholder="Entrez le nom"
                                                />
                                                <ErrorMessage
                                                    name="message"
                                                    component="div"
                                                    className="text-danger"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-example-int">
                                        <div className="form-group">
                                            <label>Url</label>
                                            <div className="nk-int-st">
                                                <Field
                                                    type="text"
                                                    name="pathAudio"
                                                    className="form-control input-sm"
                                                    placeholder="Entrez le nom"
                                                />
                                                <ErrorMessage
                                                    name="pathAudio"
                                                    component="div"
                                                    className="text-danger"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    
                                <div className="form-example-int mg-t-15">
                                    <button
                                        type="submit"
                                        className="btn btn-success notika-btn-success"
                                    >
                                        Enregistrer
                                    </button>
                                </div>
                                </Form>
                            )}
                            </Formik>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-default"
                                data-dismiss="modal"
                                ref={closeModalToUpdatePodcast}
                            >
                                Fermer
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* Modal to see detail podcast */}
            <div class="modal fade" id="myModalToSeeDetail" tabindex="-1" role="dialog" aria-labelledby="myModalToSeeDetailLabel">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <h4 class="modal-title" id="myModalToSeeDetailLabel">Informations</h4>
                        </div>
                        <div class="modal-body">
                            <p><strong>Titre :</strong> <span id="modal-name">{podcastToDelete?.title}</span></p>
                            <p><strong>Message :</strong> <span id="modal-type">{podcastToDelete?.message}</span></p>
                            <p><strong>Url :</strong> <span id="modal-description">{podcastToDelete?.pathAudio}</span></p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-default" data-dismiss="modal">Fermer</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Podcasts;