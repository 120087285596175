import React, { useState, useEffect, useRef } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import culturalWealthService from '../services/culturalWealthService';
import { toast } from 'react-toastify';
import postService from '../services/postService';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import ReactPlayer from 'react-player';
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';
import { Circles } from 'react-loader-spinner';


function GenesisKingdom(props) {
    // État pour gérer l'état du toggle
    const [isChecked, setIsChecked] = useState(false);
    const [loading, setLoading] = useState(false)
    const [CulturalWealths, setCulturalWealths] = useState([]);
    const [totalNumberOfPage, setTotalNumberOfPage] = useState(null);
    const [search, setSearch] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [numberPerPage, setNumberPerPage] = useState(10);
    const closeModalToDelete = useRef(null);
    const closeModalToAddPhotoAndVideo = useRef(null);
    const [traditionalDanseUpdateOrDelete, setTraditionalDanseUpdateOrDelete] = useState(null);
    const [isCheckedVideo, setIsCheckedVideo] = useState(false);
    const [traditionalDanseUpdate, setTraditionalDanseUpdate] = useState(null);
    const closeModalToUpdate = useRef(null);
    const navigate = useNavigate()

    // Fonction pour gérer le changement de l'état du toggle
    const handleToggle = () => {
        setIsChecked(!isChecked);
    };

    const handleToggleVideo = () => {
        setIsCheckedVideo(!isCheckedVideo)
    }

    const listTraditionalDanse = async(search, page, numberPerPage) => {
        const data = {};
        data.search = search;
        data.page = page+1; 
        data.numberPerPage = numberPerPage;
        data.type = "genesis"
        setLoading(true);
        try {
            const responseDiaporaCommunity = await culturalWealthService.listCulturalWealths(data)
            if (responseDiaporaCommunity.errorResponse) {
                toast.error(responseDiaporaCommunity.message)
            } else {
                setCulturalWealths(responseDiaporaCommunity.results);
                setTotalNumberOfPage(responseDiaporaCommunity.numberOfPages)
            }
        } catch (error) {
            toast.error("Une erreur est obtenu pendant l'obtension des diaspora")
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        listTraditionalDanse(search, currentPage, numberPerPage)
    }, [search, currentPage, numberPerPage]);

    const deletePost = async (id) => {
        try {
            setLoading(true)
            const response = await postService.deletePost(id)
            if (response?.errorResponse) {
                toast.error(response.message)
            } else {
                toast.success("Le post a été Supprimé avec succès")
                console.log("Suppression avant =>>")
                listTraditionalDanse(search, currentPage, numberPerPage);
                console.log("Suppression ensuite =>>")
                closeModalToDelete.current.click();
            }
            
        } catch (error) {
            console.error("Erreur lors de la suppression du post chambre:", error)
            toast.error("Une erreur inattendue s'est produite lors de la suppression de la chambre.")
        } finally {
            setLoading(false)
        }
    }

    // Définir le schéma de validation avec Yup
    const validationSchema = Yup.object().shape({
        title: Yup.string().required("Le titre est requis"),
        description: Yup.string().required("La description est requise"),
        message: Yup.string().required("Le message est requis"),
        type: Yup.string().oneOf(["genesis"], "Type invalide"),
    });

    const validationPhotoSchema = Yup.object({
        photo: Yup.mixed().required('La photo est requise'),
      });

    const handleSubmit = async (values) => {
        setLoading(true);
        const postData = {
            title: values.title,
            description: values.description,
            message: values.message,
            type: values.type,
        }
        const responsePost = await postService.createPost(postData)
        try {
            if(responsePost.errorResponse) {
                toast.error(responsePost.message)
            } else {
                setIsChecked(false)
                listTraditionalDanse(search, currentPage, numberPerPage)
                toast("La création de type danse traditionnelle a été éffectué avec succès")
    
            }
        } catch (error) {
            toast.error("Une érreur inattendue s'est produite lors de la creation du post")
            console.log("Une érreur a été survenu lors de la création d'un => ", error)
        } finally {
            setLoading(false)
        }
    }

    const handlePhotoSubmit = async (values) => {
        const formData = new FormData()
        formData.append('photo', values.photo)
        formData.append('post_id', traditionalDanseUpdateOrDelete.id);

        const responseAddPhoto = await postService.addPhotoPost(formData)
        try {
            if (responseAddPhoto.errorResponse) {
                toast.error(responseAddPhoto.message)
            } else {
                toast("L'ajout de la photo a été éffectué avec succès");
                closeModalToAddPhotoAndVideo.current.click()
                listTraditionalDanse(search, currentPage, numberPerPage)
            }
        } catch (error) {
            toast.error("Une érreur inattendue s'est produite lors de l'ajout de ce video")
        }
        
    };

    const handleVideoSubmit = async (values) => {
        const formData = new FormData()
        formData.append('video', values.video)
        formData.append('post_id', traditionalDanseUpdateOrDelete.id);
        formData.append('description', values.description);
        formData.append('title', values.title);
        formData.append('miniature', values.miniature);

        const responseAddVideo = await postService.addVideoPost(formData);

        try {
            if (responseAddVideo.errorResponse) {
                toast.error(responseAddVideo.message)
            } else {
                toast("L'ajout de la photo a été éffectué avec succès");
                closeModalToAddPhotoAndVideo.current.click()
                listTraditionalDanse(search, currentPage, numberPerPage)
            }
        } catch (error) {
            toast.error("Une érreur est survenue lors de l'ajout de cette video")
        }
        
    };

    const validationVideoSchema = Yup.object().shape({
        video: Yup.string()
            .required("Le lien de la vidéo est requis")
            .matches(
                /^(https?\:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$/,
                "Veuillez entrer un lien YouTube valide"
            ),
        description: Yup.string()
            .required("La description est requise")
            .min(10, "La description doit contenir au moins 10 caractères"),
        title: Yup.string()
            .required("Le titre est requis")
            .min(3, "Le titre doit contenir au moins 3 caractères"),
        miniature: Yup.mixed()
            .required("La miniature est requise")
            .test(
                "fileFormat",
                "Seules les images jpg/jpeg sont autorisées",
                (value) => value && ["image/jpeg", "image/jpg"].includes(value.type)
            ),
    });

    const handleUpdateSubmit = async (values) => {
        const postData = {
            title: values.title,
            description: values.description,
            message: values.message,
            type: values.type,
        };

        const responsePost = await postService.updateDetailOfTraditionalDanse(traditionalDanseUpdate.id, postData)
        try {
            if(responsePost.errorResponse) {
                toast.error(responsePost.message)
            } else {
                setIsChecked(false)
                listTraditionalDanse(search, currentPage, numberPerPage)
                toast("La création de type danse traditionnelle a été éffectué avec succès")
                closeModalToUpdate.current.click()
            }
        } catch (error) {
            toast.error("Une érreur inattendue s'est produite lors de la creation du post")
            console.log("Une érreur a été survenu lors de la création d'un => ", error)
        } finally {
            setLoading(false)
        }
    };

    const handleSearchAnObject = (event) => {
        const searchAnObject = event.target.value;
        setSearch(searchAnObject)
        setCurrentPage(0)
    };

    const handleChangeCurrentPage = (data) => {
        let currentPageu = data.selected;
        console.log('mon current ==>>> ', currentPageu)
        setCurrentPage(currentPageu)
        return ;
    
    };

    const handleClick = (royalPost) => {
        //dispatch(getIdAndtypeCommunity({ communityId: nameCommunity.id, type: nameCommunity.type }));
        // Naviguer vers /posts/:nameCommunity1
        navigate(`/post-genesis/${royalPost.id}`);
    }
    return (
        <>
            <Header />
            <div class="form-element-area">
                {loading && (
                    <div style={{
                        position: 'absolute', // Position absolue pour centrer l'élément
                        top: '50%', // Positionne à 50% de la hauteur du conteneur
                        left: '50%', // Positionne à 50% de la largeur du conteneur
                        transform: 'translate(-50%, -50%)' // Ajuste pour centrer précisément
                    }}>
                        <Circles /> {/* Ton composant Circles ici */}
                    </div>
                )}
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="form-element-list">
                                <div class="basic-tb-hd">
                                    <h2>Activer la création d'un Post de genèse</h2>
                                    <div class="TriSea-technologies-Switch">
                                        <input
                                            id="TriSeaPrimary"
                                            name="TriSea1"
                                            type="checkbox"
                                            checked={isChecked}
                                            onChange={handleToggle}
                                        />
                                        <label for="TriSeaPrimary" class="label-primary"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
            {isChecked ? (
                <>
                    <div className="accordion-area">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="accordion-wn-wp mg-t-30">
                                        <div className="accordion-hd">
                                            <h2>Création d'un Post</h2>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-6 col-xs-12">
                                                <div className="accordion-stn">
                                                    <div className="panel-group" data-collapse-color="nk-indigo" id="accordionIndigo" role="tablist" aria-multiselectable="true">
                                                        <div className="panel panel-collapse notika-accrodion-cus">
                                                            <div className="panel-heading" role="tab">
                                                                <h4 className="panel-title">
                                                                    <a className="collapsed" data-toggle="collapse" data-parent="#accordionIndigo" href="#accordionIndigo-two" aria-expanded="false">
                                                                        Cliquez ici pour voir le formulaire
                                                                    </a>
                                                                </h4>
                                                            </div>
                                                            <div id="accordionIndigo-two" className="collapse animated zoomInLeft" role="tabpanel">
                                                                <div className="panel-body">
                                                                    <Formik
                                                                        initialValues={{ title: '', description: '', message: '', type: 'genesis' }}
                                                                        validationSchema={validationSchema}
                                                                        onSubmit={handleSubmit}
                                                                    >
                                                                        {() => (
                                                                            <Form className="form-example-wrap">
                                                                                <div className="form-example-int">
                                                                                    <div className="form-group">
                                                                                        <label>Title</label>
                                                                                        <div className="nk-int-st">
                                                                                            <Field
                                                                                                type="text"
                                                                                                name="title"
                                                                                                className="form-control input-sm"
                                                                                                placeholder="Enter Title"
                                                                                            />
                                                                                            <ErrorMessage name="title" component="div" className="text-danger" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="form-example-int">
                                                                                    <div className="form-group">
                                                                                        <label>Description</label>
                                                                                        <div className="nk-int-st">
                                                                                            <Field
                                                                                                as="textarea"
                                                                                                name="description"
                                                                                                className="form-control input-sm"
                                                                                                placeholder="Veuillez saisir la description"
                                                                                            />
                                                                                            <ErrorMessage name="description" component="div" className="text-danger" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="form-example-int">
                                                                                    <div className="form-group">
                                                                                        <label>Message</label>
                                                                                        <div className="nk-int-st">
                                                                                            <Field
                                                                                                as="textarea"
                                                                                                name="message"
                                                                                                className="form-control input-sm"
                                                                                                placeholder="Enter your message"
                                                                                            />
                                                                                            <ErrorMessage name="message" component="div" className="text-danger" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="form-example-int">
                                                                                    <div className="form-group">
                                                                                        <label>Type</label>
                                                                                        <div className="nk-int-st">
                                                                                            <Field
                                                                                                type="text"
                                                                                                name="type"
                                                                                                className="form-control input-sm"
                                                                                                disabled
                                                                                            />
                                                                                            <ErrorMessage name="type" component="div" className="text-danger" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="form-example-int mg-t-15">
                                                                                    <button type="submit" className="btn btn-success notika-btn-success">Submit</button>
                                                                                </div>
                                                                            </Form>
                                                                        )}
                                                                    </Formik>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>  
                </>
            ) : (
                <>
                    <div className="accordion-area">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="accordion-wn-wp mg-t-30">
                                        <div className="accordion-hd">
                                            <h2>Filtre par la recherche et par le nombre d'élément</h2>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-6 col-xs-12">
                                                <div className="accordion-stn" >
                                                    <div className="col-6">
                                                        <div class="dataTables_length" id="data-table-basic_length">
                                                            <label>
                                                                Présente 
                                                                <select name="data-table-basic_length" aria-controls="data-table-basic" class="">
                                                                    <option value="10">10</option>
                                                                    <option value="25">25</option>
                                                                    <option value="50">50</option>
                                                                    <option value="100">100</option>
                                                                </select> 
                                                                entrées
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className='col-6'>
                                                        <div id="data-table-basic_filter" class="dataTables_filter">
                                                            <label>Search:
                                                                <input 
                                                                    type="search" 
                                                                    class="" 
                                                                    placeholder="" 
                                                                    aria-controls="data-table-basic"
                                                                    onChange={handleSearchAnObject}
                                                                />
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container" style={{marginTop: "7px"}}>
                        <h2 className="text-center" style={{ marginBottom: "20px" }}>Postes de genèses du royaume</h2>
                        <div class="row">
                            {CulturalWealths?.map((culturalWealth, index) => (
                                <div 
                                    className="col-md-6" 
                                    style={{transition: "transform 0.3s",cursor: "pointer"}}
                                    onMouseEnter={(e) => e.currentTarget.style.transform = "scale(1.03)"}
                                    onMouseLeave={(e) => e.currentTarget.style.transform = "scale(1)"}
                                >
                                    <div className="thumbnail" style={{background: "rgba(34, 58, 102, 0.2)"}}>
                                        <div className="caption">
                                            <h3 role='button' onClick={() => handleClick(culturalWealth)}>{culturalWealth.title}</h3>
                                            <div className='button-container' style={{ display: "flex", justifyContent: "space-between" }}>
                                                <button
                                                    className="btn"
                                                    style={{ background: "rgb(34, 58, 102)", color: "white" }}
                                                    role="button"
                                                    data-toggle="modal"
                                                    data-target="#myModaltwo"
                                                    onClick={() => setTraditionalDanseUpdateOrDelete(culturalWealth)}
                                                >
                                                    Ajouter image/vidéo
                                                </button>
                                                <button
                                                    className="btn"
                                                    style={{ background: "rgb(34, 58, 102)", color: "white" }}
                                                    role="button"
                                                    data-toggle="modal"
                                                    data-target="#myModalToSeeDetail"
                                                    onClick={() => setTraditionalDanseUpdateOrDelete(culturalWealth)}
                                                >
                                                    Détails
                                                </button>
                                                <button
                                                    className="btn"
                                                    style={{ background: "rgb(34, 58, 102)", color: "white" }}
                                                    data-toggle="modal"
                                                    data-target="#myModalToUpdate"
                                                    onClick={() => setTraditionalDanseUpdate(culturalWealth)}
                                                >
                                                    Modifier
                                                </button>
                                                <button
                                                    className="btn btn-danger"
                                                    role="button"
                                                    data-toggle="modal"
                                                    data-target="#myModalsix"
                                                    onClick={() => setTraditionalDanseUpdateOrDelete(culturalWealth)}
                                                >
                                                    Supprimer
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))} 
                        </div>
                        <ReactPaginate
                            key={currentPage}
                            initialPage={currentPage}
                            previousLabel={"<"}
                            nextLabel={">"}
                            breakLabel={"..."}
                            pageCount={totalNumberOfPage}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={3}
                            onPageChange={handleChangeCurrentPage}
                            containerClassName={"pagination justify-content-center"}
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeLinkClassName={"active"}
                            activeClassName={"active"}
                        />
                    </div>  
                </>
            )}
            <div className="modal fade" id="myModaltwo" role="dialog">
                <div className="modal-dialog modal-large">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                    <div className="modal-body">
                        <h2>Ajout vidéo/ image</h2>
                        <div className="form-element-area">
                            <div className="TriSea-technologies-Switch">
                                <input
                                    id="TriSeaPrimaryjkjkh"
                                    type="checkbox"
                                    checked={isCheckedVideo}
                                    onChange={handleToggleVideo}
                                />
                                <label htmlFor="TriSeaPrimaryjkjkh" className="label-primary"></label>
                            </div>
                            <h4>{isCheckedVideo ? 'Formulaire d’ajout de vidéo' : 'Formulaire de photo'}</h4>
                        </div>
                        {isCheckedVideo ? (
                        // Form for adding videos
                            <Formik
                                initialValues={{ video: '', description: '', title: '', miniature: null }}
                                validationSchema={validationVideoSchema}
                                onSubmit={handleVideoSubmit}
                            >
                                {({ setFieldValue }) => (
                                <Form className="form-example-wrap">
                                    <div className="form-example-int">
                                        <div className="form-group">
                                            <label>Vidéo (Lien YouTube)</label>
                                            <div className="nk-int-st">
                                                <Field
                                                    type="text"
                                                    name="video"
                                                    className="form-control input-sm"
                                                    placeholder="Entrez le lien de la vidéo YouTube"
                                                />
                                                <ErrorMessage name="video" component="div" className="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-example-int">
                                        <div className="form-group">
                                            <label>Description</label>
                                            <div className="nk-int-st">
                                                <Field
                                                    as="textarea"
                                                    name="description"
                                                    className="form-control input-sm"
                                                    placeholder="Veuillez saisir la description"
                                                />
                                                <ErrorMessage name="description" component="div" className="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-example-int">
                                        <div className="form-group">
                                            <label>Titre</label>
                                            <div className="nk-int-st">
                                                <Field
                                                    type="text"
                                                    name="title"
                                                    className="form-control input-sm"
                                                    placeholder="Enter Title"
                                                />
                                                <ErrorMessage name="title" component="div" className="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-example-int">
                                        <div className="form-group">
                                            <label>Miniature</label>
                                            <div className="nk-int-st">
                                                <input
                                                    type="file"
                                                    name="miniature"
                                                    className="form-control input-sm"
                                                    onChange={(event) => setFieldValue('miniature', event.currentTarget.files[0])}
                                                />
                                                <ErrorMessage name="miniature" component="div" className="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-example-int mg-t-15">
                                        <button type="submit" className="btn btn-success notika-btn-success">Ajouter une vidéo</button>
                                    </div>
                                </Form>
                                )}
                            </Formik>
                        ) : (
                            // Form for photo
                            <Formik
                                initialValues={{ photo: null }}
                                validationSchema={validationPhotoSchema}
                                onSubmit={handlePhotoSubmit}
                            >
                                {({ setFieldValue }) => (
                                <Form className="form-example-wrap">
                                    <div className="form-example-int">
                                        <div className="form-group">
                                            <label>Photo</label>
                                            <div className="nk-int-st">
                                                <input
                                                    type="file"
                                                    name="photo"
                                                    className="form-control input-sm"
                                                    onChange={(event) => setFieldValue('photo', event.currentTarget.files[0])}
                                                />
                                                <ErrorMessage name="photo" component="div" className="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-example-int mg-t-15">
                                        <button type="submit" className="btn btn-success notika-btn-success">Ajouter une Photo</button>
                                    </div>
                                </Form>
                                )}
                            </Formik>
                        )}
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            ref={closeModalToAddPhotoAndVideo}
                            className="btn btn-default"
                            data-dismiss="modal"
                        >
                        Close
                        </button>
                    </div>
                    </div>
                    
                </div>
                
            </div>
            
            {/* Modal to update */}
            <div className="modal fade" id="myModalToUpdate" role="dialog">
                <div className="modal-dialog modal-large">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <h2>Modifier Danse traditionnelle</h2>
                            <Formik
                                enableReinitialize = {true}
                                initialValues={{
                                    title: traditionalDanseUpdate?.title || '',
                                    description: traditionalDanseUpdate?.description || '',
                                    message: traditionalDanseUpdate?.message || '',
                                    type: 'genesis'
                                }}
                                validationSchema={validationSchema}
                                onSubmit={handleUpdateSubmit}
                            >
                                {() => (
                                    <Form className="form-Sexample-wrap">
                                        <div className="form-example-int">
                                            <div className="form-group">
                                                <label>Titre</label>
                                                <div className="nk-int-st">
                                                    <Field
                                                        type="text"
                                                        name="title"
                                                        className="form-control input-sm"
                                                        placeholder="Entrez le titre"
                                                    />
                                                    <ErrorMessage name="title" component="div" className="text-danger" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-example-int">
                                            <div className="form-group">
                                                <label>Description</label>
                                                <div className="nk-int-st">
                                                    <Field
                                                        as="textarea"
                                                        name="description"
                                                        className="form-control input-sm"
                                                        placeholder="Veuillez saisir la description"
                                                    />
                                                    <ErrorMessage name="description" component="div" className="text-danger" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-example-int">
                                            <div className="form-group">
                                                <label>Message</label>
                                                <div className="nk-int-st">
                                                    <Field
                                                        as="textarea"
                                                        name="message"
                                                        className="form-control input-sm"
                                                        placeholder="Entrez votre message"
                                                    />
                                                    <ErrorMessage name="message" component="div" className="text-danger" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-example-int">
                                            <div className="form-group">
                                                <label>Type</label>
                                                <div className="nk-int-st">
                                                    <Field
                                                        type="text"
                                                        name="type"
                                                        className="form-control input-sm"
                                                        placeholder="Entrez le type"
                                                        disabled
                                                    />
                                                    <ErrorMessage 
                                                        name="type" 
                                                        component="div" 
                                                        className="text-danger" 
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-example-int mg-t-15">
                                            <button type="submit" className="btn btn-success notika-btn-success">
                                                Soumettre
                                            </button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                ref={closeModalToUpdate}
                                className="btn btn-default"
                                data-dismiss="modal"
                            >
                                Fermer
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* Modal pour voir la description */}
            <div class="modal fade" id="myModaldescription" role="dialog">
                <div class="modal-dialog modal-large">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div class="modal-body">
                            {/* <!-- Card 1: Titre --> */}
                            <div class="panel panel-default">
                                <div class="panel-heading">
                                    <h3 class="panel-title">{traditionalDanseUpdateOrDelete?.title}</h3>
                                </div>
                            </div>
                            {/* <!-- Card 2: Message --> */}
                            <div class="panel panel-default">
                                <div class="panel-heading">
                                    <h3 class="panel-title">Message</h3>
                                </div>
                                <div class="panel-body">
                                    {traditionalDanseUpdateOrDelete?.message}
                                </div>
                            </div>
                            {/* <!-- Card 3: Description --> */}
                            <div class="panel panel-default">
                                <div class="panel-heading">
                                    <h3 class="panel-title">Description</h3>
                                </div>
                                <div class="panel-body">
                                    {traditionalDanseUpdateOrDelete?.description}
                                </div>
                            </div>
                            {/* <!-- Card 4: Image --> */}
                            <div class="panel panel-default">
                                <div class="panel-heading">
                                    <h3 class="panel-title">Image</h3>
                                </div>
                                <div class="panel-body text-center">
                                    <div
                                        class="image-container"
                                        style={{
                                            backgroundImage: `url(${process.env.REACT_APP_SERVER}${traditionalDanseUpdateOrDelete?.photos[0]?.path})`,
                                            
                                            width: "100%",
                                            height: "300px", /* Hauteur fixe pour le cadre */
                                            backgroundSize: "cover", /* L'image couvre tout le div */
                                            backgroundPosition: "center", /* L'image est centrée */
                                            backgroundRepeat: "no-repeat" /* L'image ne se répète pas */
                                            
                                            }}
                                    >
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            {/* <button type="button" class="btn btn-default" data-dismiss="modal">Save changes</button> */}
                            <button type="button" class="btn btn-default" data-dismiss="modal">Fermer</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* Modal pour supprimer */}
            <div class="modal animated rubberBand" id="myModalsix" role="dialog">
                <div class="modal-dialog modals-default">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div class="modal-body">
                            <h2>Suppression d'un post</h2>
                            <p>Voulez-vous vraiment supprimer ce Post ?</p>
                        </div>
                        <div class="modal-footer">
                            <button 
                                type="button" 
                                class="btn btn-default" 
                                data-dismiss="modal" 
                                onClick={() => deletePost(traditionalDanseUpdateOrDelete.id)}
                            >
                                Supprimer
                            </button>
                            <button 
                                type="button" 
                                class="btn btn-default" 
                                data-dismiss="modal"
                                ref={closeModalToDelete}
                            >
                                Annuler
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div id="myModalToSeeDetail" className="modal fade" role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content">
                        {/* En-tête du modal */}
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal">
                                &times;
                            </button>
                            <h4 className="modal-title"></h4>
                        </div>
                        <div className="modal-body">
                        <p><strong>Message : {traditionalDanseUpdateOrDelete?.message}</strong> </p>
                        <p><strong>Description : {traditionalDanseUpdateOrDelete?.description}</strong> </p>
                        <p><strong>Nombre de photos : {traditionalDanseUpdateOrDelete?.photos.length}</strong> </p>
                        <p><strong>Nombre de vidéos : {traditionalDanseUpdateOrDelete?.videos.length}</strong> </p>
                        </div>
                        {/* Pied du modal */}
                        <div className="modal-footer">
                        <button type="button" className="btn btn-default" data-dismiss="modal">
                            Fermer
                        </button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default GenesisKingdom;